import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { GetOrderByEmailIdResponse } from '../../core';
import { OrdersService } from '../../services';

@Injectable()
export class SubmitOrderResolver {
  constructor(private readonly ordersService: OrdersService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<GetOrderByEmailIdResponse> | Observable<GetOrderByEmailIdResponse> {
    return this.ordersService.getOrderByEmailId(route.params.id as string);
  }
}
