import { IANATimezone } from '@supy.api/dictionaries';

import { getApiDetailsDecorator, IdType, removeEmpty, SkeletonObjectType, SupplierSnapshot } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  SupplierReturnBaseRequest,
  SupplierReturnCreateRequest,
  SupplierReturnDocument,
  SupplierReturnItem,
  SupplierReturnUpdateRequest,
} from '../../entities';
import { SupplierReturnDocumentPayload } from './supplier-return-document.payload';
import { SupplierReturnItemPayload } from './supplier-return-item.payload';

const ApiProperty = getApiDetailsDecorator<SupplierReturnCreateRequest | SupplierReturnUpdateRequest>();

export abstract class SupplierReturnBasePayload {
  protected constructor(args: NonFunctionProperties<SupplierReturnBasePayload>) {
    this.comment = args.comment;
    this.document = args.document;
    this.items = args.items;
    this.notifySupplier = args.notifySupplier;
    this.pushToStock = args.pushToStock;
    this.ianaTimeZone = args.ianaTimeZone;
    this.utcOffset = args.utcOffset;
  }

  @ApiProperty() readonly comment?: string;
  @ApiProperty() document: SupplierReturnDocument;
  @ApiProperty() readonly items: SupplierReturnItem[];
  @ApiProperty() readonly notifySupplier?: boolean;
  @ApiProperty() readonly pushToStock?: boolean;

  readonly ianaTimeZone: IANATimezone;
  readonly utcOffset: number;

  protected serializeBase(): SupplierReturnBaseRequest {
    return {
      document: new SupplierReturnDocumentPayload({
        ...this.document,
        number: this.document.number.trim(),
        ianaTimeZone: this.ianaTimeZone,
        utcOffset: this.utcOffset,
      }).serialize(),
      items: this.items.map(item => new SupplierReturnItemPayload(item).serialize()),
      comment: this.comment,
      notifySupplier: this.notifySupplier ?? false,
      pushToStock: this.pushToStock ?? false,
    };
  }

  appendFiles(files: string[]): void {
    const { attachments, ...props } = this.document;

    this.document = {
      ...props,
      attachments: attachments.concat(files.map(file => ({ path: file, signedUrl: file }))),
    };
  }
}

export class SupplierReturnCreatePayload extends SupplierReturnBasePayload {
  constructor(args: NonFunctionProperties<Omit<SupplierReturnCreatePayload, 'creationSource'>>) {
    super(args);
    this.channel = args.channel;
    this.creationSource = 'location';
    this.location = args.location;
    this.retailer = args.retailer;
    this.supplier = args.supplier;
  }

  @ApiProperty() readonly channel: IdType;
  @ApiProperty() readonly creationSource: 'location';
  @ApiProperty() readonly location: SkeletonObjectType;
  @ApiProperty() readonly retailer: IdType;
  @ApiProperty() readonly supplier: SupplierSnapshot;

  serialize(): SupplierReturnCreateRequest {
    return removeEmpty({
      ...this.serializeBase(),
      channel: { id: this.channel.id },
      creationSource: this.creationSource,
      location: { id: this.location.id },
      retailer: { id: this.retailer.id },
      supplier: { id: this.supplier.id },
    });
  }
}

export class SuppierReturnUpdatePayload extends SupplierReturnBasePayload {
  constructor(args: NonFunctionProperties<SuppierReturnUpdatePayload>) {
    super(args);
  }

  serialize(): SupplierReturnUpdateRequest {
    return {
      ...this.serializeBase(),
    };
  }
}
