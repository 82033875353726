import { Query } from '@supy/common';

import {
  CategoryOrder,
  CategoryOrderBasket,
  CreateCategoryOrderRequest,
  DiscardBranchCategoryOrderRequest,
  ProcessBranchCategoryOrderRequest,
} from '../../core';

export class CategoryOrdersGetMany {
  static type = '[CategoryOrders] GetCategoryOrders';

  constructor(
    readonly query: Query<CategoryOrder>,
    readonly byBranch = false,
  ) {}
}

export class CategoryOrdersGet {
  static type = '[CategoryOrders] GetCategoryOrder';

  constructor(readonly id: string) {}
}

export class CategoryOrdersUpdateBasket {
  static type = '[CategoryOrders] UpdateBasket';

  constructor(readonly args: Partial<CategoryOrderBasket>) {}
}

export class CategoryOrdersResetBasket {
  static type = '[CategoryOrders] ResetBasket';
}

export class CategoryOrdersCreate {
  static type = '[CategoryOrders] CreateCategoryOrder';

  constructor(readonly args: Omit<CreateCategoryOrderRequest, 'orderedPackages'>) {}
}

export class CategoryOrdersProcess {
  static type = '[CategoryOrders] ProcessCategoryOrder';

  constructor(readonly payload: ProcessBranchCategoryOrderRequest) {}
}

export class CategoryOrdersDiscard {
  static type = '[CategoryOrders] DiscardCategoryOrder';

  constructor(readonly payload: DiscardBranchCategoryOrderRequest) {}
}
