import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseHttpService, IQueryResponse, Query } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { CategoryOrdersConfig } from '../../config';
import {
  CategoryOrder,
  CreateCategoryOrderRequest,
  DiscardBranchCategoryOrderRequest,
  ProcessBranchCategoryOrderRequest,
  ProcessCategoryOrderResponse,
} from '../../core';

@Injectable({ providedIn: 'root' })
export class CategoryOrdersService extends BaseHttpService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) protected readonly config: CategoryOrdersConfig,
  ) {
    super(`${config.apiUrlBff}${config.apiUrlPrefix}/category-orders`);
  }

  getCategoryOrders(query: Query<CategoryOrder>, byBranch = false): Observable<IQueryResponse<CategoryOrder>> {
    return this.get<IQueryResponse<CategoryOrder>>(byBranch ? 'branch' : '', query.toQueryParams());
  }

  getCategoryOrder(orderId: string): Observable<CategoryOrder> {
    return this.get(orderId);
  }

  createCategoryOrder(categoryOrder: CreateCategoryOrderRequest): Observable<CategoryOrder> {
    return this.post(categoryOrder);
  }

  processCategoryOrder(body: ProcessBranchCategoryOrderRequest): Observable<ProcessCategoryOrderResponse> {
    return this.post(body, 'process');
  }

  discardCategoryOrder(body: DiscardBranchCategoryOrderRequest): Observable<void> {
    return this.post(body, 'discard');
  }
}
