import { inject, InjectionToken } from '@angular/core';

import { CommonConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const ORDERS_URI = new InjectionToken<string>('Orders Core URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrl}${config.apiUrlPrefix}/orders`;
  },
});

export const ORDERS_BFF_URI = new InjectionToken<string>('Orders BFF URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/orders`;
  },
});

export const GNNIS_URI = new InjectionToken<string>('Gnnis URI', {
  factory: () => {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrl}${config.apiUrlPrefix}/gnnis`;
  },
});

export const GNNIS_BFF_URI = new InjectionToken<string>('Gnnis BFF URI', {
  factory: () => {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/ggnis`;
  },
});
