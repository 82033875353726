import { Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

import { UnleashService } from '@supy/common';
import { CurrentRetailerUnleashGuard } from '@supy/retailers';

/**
 * @description
 * Used as the guard that validates the provided `toggles` for the retailer based on the current retailer id
 *
 * @usage
 * ```Typescript
 * const routes: UnleashRoute[] = [
 *  {
 *    path: '',
 *    canActivate: [RedirectToOrdersUnleashGuard],
 *    data: {
 *      unleashContext: {toggles: [ToggleFeature.SomeFeature]}
 *    }
 *  }
 * ];
 * ```
 */
@Injectable({ providedIn: 'root' })
export class RedirectToOrdersUnleashGuard extends CurrentRetailerUnleashGuard {
  constructor(
    protected readonly unleashService: UnleashService,
    protected readonly store: Store,
    protected readonly router: Router,
  ) {
    super(unleashService, store);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return super.canActivate(route).pipe(
      tap(hasToggle => {
        if (!hasToggle) {
          this.store.dispatch(new Navigate(['/orders']));
        }
      }),
    );
  }
}
