import { NonFunctionProperties } from '@supy/core';

import { SupplierReturnItemBaseTaxRate, SupplierReturnItemTaxRateRequest } from '../../entities';

export class SupplierReturnItemTaxRatePayload extends SupplierReturnItemBaseTaxRate {
  constructor(args: NonFunctionProperties<SupplierReturnItemTaxRatePayload>) {
    super(args);
    this.itemPrice = args.itemPrice;
    this.itemQuantity = args.itemQuantity;
  }

  readonly itemPrice: number;
  readonly itemQuantity: number;

  serialize(): SupplierReturnItemTaxRateRequest {
    return {
      name: this.name,
      rate: this.rate,
      taxCode: this.taxCode,
      amount: (this.rate / 100) * this.itemPrice * this.itemQuantity,
    };
  }
}
