import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CentralKitchenOrderActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Ship extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Ship,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Confirm extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Confirm,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Reject extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CentralKitchenOrder,
      action: CentralKitchenOrderActionEnum.Reject,
      resource,
      principal,
    });
  }
}

export class CkOrderPermissionStrategy {
  static Create = Create;
  static Ship = Ship;
  static Confirm = Confirm;
  static Reject = Reject;
}
