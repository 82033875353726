import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IdType, IQueryResponse, Query, UploadFileResponse } from '@supy/common';

import { ORDERS_BFF_URI, ORDERS_URI } from '../../config';
import {
  AggregatedOrdersResponse,
  AggregatedSupplierOrdersResponse,
  CreateOrderRequest,
  DetailedOrder,
  DownloadDeliveryNoteResponse,
  DownloadOrderPdfResponse,
  GetOrderByEmailIdResponse,
  Invoice,
  ModifyOrderRequest,
  Order,
  OrderRequestProps,
  RejectOrderRequest,
  ReturnOrderItemsRequest,
  UpdateOrderRequest,
} from '../../core';

@Injectable()
export class OrdersService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(ORDERS_URI) private readonly uri: string,
    @Inject(ORDERS_BFF_URI) private readonly uriBff: string,
  ) {}

  getOrdersBff(query: Query<Order>): Observable<IQueryResponse<Order>> {
    return this.httpClient.get<IQueryResponse<Order>>(this.uriBff, { params: query.toQueryParams() });
  }

  getOrdersPostBff(query: Query<Order & OrderRequestProps>): Observable<IQueryResponse<Order>> {
    return this.httpClient.post<IQueryResponse<Order>>(`${this.uriBff}/get`, query);
  }

  getOrdersPostBffV2(query: Query<Order & OrderRequestProps>): Observable<IQueryResponse<Order>> {
    return this.httpClient.post<IQueryResponse<Order>>(`${this.uriBff}/get`, query, {
      headers: { 'X-Version': '2' },
    });
  }

  getCkOrdersPostBff(query: Query<Order & OrderRequestProps>): Observable<IQueryResponse<Order>> {
    return this.httpClient.post<IQueryResponse<Order>>(`${this.uriBff}/ck/get`, query);
  }

  getOrdersPostWithStats(query: Query<Order & OrderRequestProps>): Observable<IQueryResponse<Order>> {
    return this.httpClient.post<IQueryResponse<Order>>(`${this.uri}/get-with-stats`, query);
  }

  getOrdersPostWithStatsBff(query: Query<Order & OrderRequestProps>): Observable<IQueryResponse<Order>> {
    return this.httpClient.post<IQueryResponse<Order>>(`${this.uriBff}/get-with-stats`, query);
  }

  getOrder(orderId: string): Observable<Order> {
    return this.httpClient.get<Order>(`${this.uri}/${orderId}`);
  }

  getDetailedOrder(orderId: string): Observable<DetailedOrder> {
    return this.httpClient.get<DetailedOrder>(`${this.uriBff}/${orderId}/detailed`);
  }

  getAggregatedOrders({ orders }: { orders: IdType[] }): Observable<AggregatedOrdersResponse> {
    return this.httpClient.post<AggregatedOrdersResponse>(`${this.uriBff}/aggregated`, { orders });
  }

  createOrder(order: Order): Observable<Order> {
    return this.httpClient.post<Order>(this.uri, order);
  }

  updateOrderBff(id: string, body: UpdateOrderRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/${id}/updates`, body);
  }

  closeOrderBff(id: string): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/${id}/close`, null);
  }

  closeOrdersBff(orders: IdType[]): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/close`, { orders });
  }

  returnOrderItemsBff(orderId: string, body: ReturnOrderItemsRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/${orderId}/return`, body);
  }

  updateInvoice(orderId: string, invoice: Invoice): Observable<void> {
    return this.httpClient.put<void>(`${this.uri}/${orderId}/invoice`, invoice);
  }

  uploadFile(file: FormData, orderId: string): Observable<UploadFileResponse> {
    return this.httpClient.post<UploadFileResponse>(`${this.uri}/${orderId}/invoice/image`, file);
  }

  getOrderByEmailId(emailId: string): Observable<GetOrderByEmailIdResponse> {
    return this.httpClient.get<GetOrderByEmailIdResponse>(`${this.uri}/email/${emailId}/info`);
  }

  editOrderByEmailId(emailId: string, body: ModifyOrderRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/email/${emailId}/modify`, body);
  }

  confirmOrderByEmailId(
    emailId: string,
    payload?: { products: { productId: string; quantity: number }[] },
  ): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/email/${emailId}/confirm`, payload);
  }

  rejectOrderByEmailId(emailId: string, reason: RejectOrderRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/email/${emailId}/reject`, reason);
  }

  getOrderPdf(orderId: string): Observable<DownloadOrderPdfResponse> {
    return this.httpClient.get<DownloadOrderPdfResponse>(`${this.uriBff}/${orderId}/download/lpo`);
  }

  getDeliveryNote(orderId: string): Observable<DownloadDeliveryNoteResponse> {
    return this.httpClient.get<DownloadDeliveryNoteResponse>(`${this.uriBff}/${orderId}/download/delivery-note`);
  }

  getOrderBff(orderId: string): Observable<Order> {
    return this.httpClient.get<Order>(`${this.uriBff}/${orderId}`);
  }

  confirmManyBff(orderIds: IdType[]) {
    return this.httpClient.post(`${this.uriBff}/confirm`, { orderIds });
  }

  shipManyBff(orderIds: IdType[], updateStock: boolean) {
    return this.httpClient.post(`${this.uriBff}/ship`, { orderIds, updateStock });
  }

  rejectManyBff(orderIds: IdType[]) {
    return this.httpClient.post(`${this.uriBff}/reject`, { orderIds });
  }

  createOrderBff(body: CreateOrderRequest): Observable<Order> {
    return this.httpClient.post<Order>(`${this.uriBff}`, body);
  }

  updateInvoiceBff(orderId: string, invoice: Invoice): Observable<void> {
    return this.httpClient.put<void>(`${this.uriBff}/${orderId}/invoice`, invoice);
  }

  uploadFileBff(file: FormData, orderId: string): Observable<UploadFileResponse> {
    return this.httpClient.post<UploadFileResponse>(`${this.uriBff}/${orderId}/invoice/image`, file);
  }

  modifyOrderBff(orderId: string, body: ModifyOrderRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uriBff}/${orderId}/modify`, body);
  }

  confirmOrderBff(orderId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/${orderId}/confirm`, undefined);
  }

  rejectOrderBff(orderId: string, reason: RejectOrderRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/${orderId}/reject`, reason);
  }

  aggregateOrdersBff(orderIds: string[]): Observable<AggregatedSupplierOrdersResponse> {
    return this.httpClient.post<AggregatedSupplierOrdersResponse>(`${this.uriBff}/aggregate`, {
      orders: orderIds.map(id => ({ id })),
    });
  }
}
