<supy-tabview-select
  [items]="tabs"
  [formControl]="tabFormControl"
  (selectedChanged)="onSelectTab($event)"
></supy-tabview-select>
<div class="retailer-orders-grid-filters__header">
  <supy-filter-group
    [filterGroup]="filtersGroup"
    [latency]="400"
    [predefinedDateRanges]="predefinedDateRanges"
    (filtersChange)="onFiltersChange($event)"
    (filtersReset)="filtersCleared.emit()"
  ></supy-filter-group>

  <div *ngIf="showLegend" class="retailer-orders-grid-filters__legend">
    <div class="retailer-orders-grid-filters__legend-item">
      <supy-icon name="shop-add" color="primary" size="small"></supy-icon>
      <span>Order created by CK</span>
    </div>
  </div>
</div>
