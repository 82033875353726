import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BadgeStatus } from '@supy/components';

import { OrderStatus } from '../../../core';

@Component({
  selector: 'supy-retailer-order-status-badge[status]',
  template: `
    <supy-icon-status-badge
      [status]="statusesMapper[status]"
      [text]="text"
      width="100%"
      weight="normal"
    ></supy-icon-status-badge>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleCasePipe],
})
export class RetailerOrderStatusBadgeComponent {
  @Input() protected readonly status: OrderStatus;
  @Input() protected readonly isInventoryOrder: boolean;
  @Input() protected readonly isCkOrder: boolean;

  constructor(private readonly titleCasePipe: TitleCasePipe) {}

  protected readonly statusesMapper: Record<OrderStatus, BadgeStatus> = {
    [OrderStatus.Submitted]: 'info',
    [OrderStatus.PartialReceived]: 'light-warn',
    [OrderStatus.Received]: 'success',
    [OrderStatus.Confirmed]: 'primary',
    [OrderStatus.Draft]: 'grey',
    [OrderStatus.Rejected]: 'error',
    [OrderStatus.Pending]: 'grey',
    [OrderStatus.Processed]: 'success',
    [OrderStatus.Modified]: 'info',
    [OrderStatus.Discarded]: 'light-error',
    [OrderStatus.Shipped]: 'primary',
    [OrderStatus.UnShipped]: 'primary',
  };

  protected get text(): string {
    switch (this.status) {
      case OrderStatus.Submitted: {
        return this.isCkOrder ? $localize`:@@newOrder:New Order` : $localize`:@@statusSubmitted:Submitted`;
      }

      case OrderStatus.Received: {
        if (this.isInventoryOrder) {
          return $localize`:@@statusClosed:Closed`;
        } else if (this.isCkOrder) {
          return $localize`:@@statusDelivered:Delivered`;
        } else {
          return $localize`:@@statusReceived:Received`;
        }
      }

      case OrderStatus.Draft:
        return $localize`:@@statusDrafted:Drafted`;
      case OrderStatus.PartialReceived:
        return $localize`:@@orderPartiallyReceived:Partially Received`;
      case OrderStatus.Shipped:
        return $localize`:@@statusShipped:Shipped`;
      case OrderStatus.Processed:
        return $localize`:@@statusProcessed:Processed`;
      case OrderStatus.Pending:
        return $localize`:@@statusPending:Pending`;
      case OrderStatus.Modified:
        return $localize`:@@statusModified:Modified`;
      case OrderStatus.Discarded:
        return $localize`:@@statusDiscarded:Discarded`;
      case OrderStatus.Confirmed:
        return $localize`:@@statusConfirmed:Confirmed`;
      case OrderStatus.Rejected:
        return $localize`:@@statusRejected:Rejected`;
      default:
        return this.titleCasePipe.transform(this.status);
    }
  }
}
