import { IdType } from '@supy/common';

export interface OrderedPackage {
  readonly channelItem: IdType;
  readonly packaging: IdType;
  readonly quantity: number;
}

export interface CreateCategoryOrderRequest {
  readonly branchId: string;
  readonly categoryId: string;
  readonly deliveryDate: Date;
  readonly comment: string;
  readonly orderedPackages: OrderedPackage[];
}

export type UpdateCategoryOrderRequest = Partial<CreateCategoryOrderRequest>;

export class ProcessBranchCategoryOrderItemsRequest {
  readonly channelItem: IdType;
  readonly quantity: number;
}

export class ProcessNestedCategoryOrderRequest {
  readonly id: string;
  readonly orderedPackages: ProcessBranchCategoryOrderItemsRequest[];
}

export class ProcessBranchCategoryOrderRequest {
  readonly branch: string;
  readonly categoryOrders: ProcessNestedCategoryOrderRequest[];
  readonly deliveryDate: Date;
  readonly isDraftOrder: boolean;
}

export interface ProcessCategoryOrderResponse {
  readonly itemCount: number;
  readonly supplierCount: number;
}

export class DiscardBranchCategoryOrderRequest {
  readonly categoryOrders: IdType[];
}
