import produce from 'immer';
import { first, map, switchMap, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  PreferredType,
  Query,
  QueryBuilder,
} from '@supy/common';
import { BaseActivity } from '@supy/components';
import { SettingsState } from '@supy/settings';

import { AggregatedOrders, DetailedOrder, Order, OrderRequestProps, OrderStatus } from '../../core';
import { OrdersService } from '../../services';
import {
  AddOrderItems,
  OrdersClose,
  OrdersCloseMany,
  OrdersCreate,
  OrdersDownloadDeliveryNote,
  OrdersDownloadLPO,
  OrdersGet,
  OrdersGetAggregated,
  OrdersGetDetailed,
  OrdersGetMany,
  OrdersGetProducts,
  OrdersGetUnlinked,
  OrdersInitFilters,
  OrdersPatchFilter,
  OrdersPatchRequestMeta,
  OrdersReset,
  OrdersResetAggregated,
  OrdersResetDetailed,
  OrdersResetFilter,
  OrdersResetUnlinked,
  OrdersSetFilter,
  OrdersSetSequence,
  OrdersUpdate,
  OrdersUpdateInvoice,
  OrdersUploadInvoiceImage,
  ReturnOrderItems,
} from '../actions';

const ORDERS_STATE_TOKEN = new StateToken<OrdersStateModel>('orders');

export interface OrdersStateModel extends EntityListStateModel<Order> {
  readonly filters: OrdersFilters;
  readonly requestMetadata: OrdersRequestMetadata;
  readonly responseMetadata: OrdersResponseMetadata;
  readonly detailedOrder: DetailedOrder;
  readonly aggregatedOrders: AggregatedOrders | null;
  readonly unlinkedOrders: Order[];
}

export interface OrdersFilters {
  readonly status: string;
  readonly branches: string[];
  readonly start: Date | number | string;
  readonly end: Date | number | string;
  readonly suppliers: string[];
  readonly preferred: boolean;
  readonly lpoRestaurant: string;
  readonly sorting: string;
}

export interface OrderSorting {
  readonly value: string;
  readonly field: string;
}

export interface OrdersRequestMetadata {
  readonly page: number;
  readonly limit: number;
  readonly branchIds: string[];
  readonly supplierIds: string[];
  readonly channelIds?: string[];
  readonly retailerId: string | null;
}

export interface OrdersResponseMetadata {
  readonly count: number;
  readonly total: number;
}

const FILTERS_DEFAULT = {
  status: null,
  branches: [],
  start: null,
  end: null,
  suppliers: [],
  preferred: false,
  lpoRestaurant: null,
  sorting: null,
};

const REQUEST_META_DEFAULT = { page: 0, limit: 25, branchIds: [], supplierIds: [], retailerId: null };
const RESPONSE_META_DEFAULT = { count: 0, total: 0 };

@State<OrdersStateModel>({
  name: ORDERS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    detailedOrder: null,
    aggregatedOrders: null,
    unlinkedOrders: [],
  },
})
@Injectable()
export class OrdersState extends EntityListState {
  readonly #store = inject(Store);

  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  constructor(
    private readonly ordersService: OrdersService,
    private readonly route: ActivatedRoute,
    protected readonly router: Router,
  ) {
    super(router);
  }

  @Selector()
  static orders(state: OrdersStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentOrder(state: OrdersStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static currentOrderNotes(state: OrdersStateModel) {
    const order = OrdersState.currentOrder(state);

    return order?.updates?.reduce<string[]>((acc, { comment }) => {
      if (comment) {
        acc.push(comment);
      }

      return acc;
    }, []);
  }

  @Selector()
  static detailedOrderNotes(state: OrdersStateModel) {
    const order = OrdersState.detailedOrder(state);

    return order?.updates?.reduce<string[]>((acc, { comment }) => {
      if (comment) {
        acc.push(comment);
      }

      return acc;
    }, []);
  }

  @Selector()
  static currentOrderActivities(state: OrdersStateModel) {
    const order = OrdersState.currentOrder(state);

    return order.updates.reduce<BaseActivity[]>(
      (acc, cur) => [{ action: cur.status, user: cur.user, createdAt: cur.createdAt }, ...acc],
      [],
    );
  }

  @Selector()
  static detailedOrderActivities(state: OrdersStateModel) {
    const order = OrdersState.detailedOrder(state);

    return order?.updates?.reduce<BaseActivity[]>(
      (acc, cur) => [{ action: cur.status, user: cur.user, createdAt: cur.createdAt }, ...acc],
      [],
    );
  }

  @Selector()
  static isFirst(state: OrdersStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: OrdersStateModel) {
    return EntityListState.isLast(state);
  }

  static order(id: string, next?: boolean) {
    return createSelector([OrdersState], (state: OrdersStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static appliedFiltersCount(state: OrdersStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static filters(state: OrdersStateModel) {
    return EntityListState.currentFilters<OrdersFilters>(state);
  }

  @Selector()
  static requestMetadata(state: OrdersStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: OrdersStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static detailedOrder(state: OrdersStateModel) {
    return state.detailedOrder;
  }

  @Selector()
  static aggregatedOrders(state: OrdersStateModel) {
    return state.aggregatedOrders;
  }

  @Selector()
  static linkableOrders(state: OrdersStateModel) {
    return state.unlinkedOrders.filter(order => order?.linkedGrns?.length === 0);
  }

  @Action(OrdersInitFilters)
  ordersInitFilter(ctx: StateContext<OrdersStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(OrdersSetFilter)
  setOrdersFilter(ctx: StateContext<OrdersStateModel>, action: OrdersSetFilter) {
    super.setFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new OrdersPatchRequestMeta({ page: 0 }), new OrdersGetMany(true)]);
  }

  @Action(OrdersPatchFilter)
  patchOrdersFilter(ctx: StateContext<OrdersStateModel>, action: OrdersPatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new OrdersPatchRequestMeta({ page: 0 }), new OrdersGetMany(true)]);
  }

  @Action(OrdersResetFilter)
  resetOrdersFilter(ctx: StateContext<OrdersStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch([new OrdersPatchRequestMeta({ page: 0 }), new OrdersGetMany(true)]);
  }

  @Action(OrdersGet)
  getOrder(ctx: StateContext<OrdersStateModel>, action: OrdersGet) {
    return super.getOne(ctx, {
      ...action.payload,
      fetchApi: () => this.ordersService.getOrderBff(action.payload.id).pipe(map(order => new Order(order))),
    });
  }

  @Action(OrdersGetProducts)
  getOrderProducts(ctx: StateContext<OrdersStateModel>, action: OrdersGetProducts) {
    const existingOrder = ctx.getState().entities.find(order => order.id === action.payload.id);

    return this.ordersService.getOrderBff(action.payload.id).pipe(
      map(order =>
        super.setOne(
          ctx,
          new Order({
            ...existingOrder,
            received: order.received,
            ordered: order.ordered,
            modified: order.modified,
            modifiedTotal: order.modifiedTotal,
          }),
        ),
      ),
    );
  }

  @Action(OrdersGetDetailed)
  getOrderDetailed(ctx: StateContext<OrdersStateModel>, action: OrdersGetDetailed) {
    return this.ordersService.getDetailedOrder(action.payload.id).pipe(
      tap(detailedOrder => {
        ctx.patchState({ detailedOrder: new DetailedOrder(detailedOrder) });
      }),
    );
  }

  @Action(OrdersResetDetailed)
  resetOrderDetailed(ctx: StateContext<OrdersStateModel>) {
    return ctx.patchState({ detailedOrder: null });
  }

  @Action(OrdersReset)
  reset(ctx: StateContext<OrdersStateModel>) {
    return super.setMany(ctx, []);
  }

  @Action(OrdersGetAggregated)
  getAggregatedOrders(ctx: StateContext<OrdersStateModel>, action: OrdersGetAggregated) {
    return this.ordersService.getAggregatedOrders(action.payload).pipe(
      tap(response => {
        ctx.patchState({
          aggregatedOrders: AggregatedOrders.deserialize(response),
        });
      }),
    );
  }

  @Action(OrdersResetAggregated)
  resetAggregatedOrders(ctx: StateContext<OrdersStateModel>) {
    return ctx.patchState({
      aggregatedOrders: null,
    });
  }

  @Action(OrdersGetMany, { cancelUncompleted: true })
  getOrders(ctx: StateContext<OrdersStateModel>, action: OrdersGetMany) {
    const fetchApi = action.excludeProducts
      ? () => this.ordersService.getOrdersPostBffV2(this.getQuery(ctx.getState()))
      : () => this.ordersService.getOrdersPostBff(this.getQuery(ctx.getState()));

    return fetchApi().pipe(
      map(({ data, metadata }) => ({
        data: data.map(order => new Order(order)),
        metadata,
      })),
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(AddOrderItems)
  addOrderItems(ctx: StateContext<OrdersStateModel>, { items }: AddOrderItems) {
    ctx.setState(
      produce(draft => {
        draft.detailedOrder = {
          ...draft.detailedOrder,
          products: [...draft.detailedOrder.products, ...items],
        };
      }),
    );
  }

  @Action(OrdersPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<OrdersStateModel>, { payload }: OrdersPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(OrdersCreate)
  createOrder(ctx: StateContext<OrdersStateModel>, { payload }: OrdersCreate) {
    return this.ordersService.createOrderBff(payload).pipe(
      tap(order => {
        ctx.setState(
          produce(draft => {
            draft.entities.push(order);
            draft.sequence.id = order.id;
          }),
        );
      }),
    );
  }

  @Action(OrdersUpdate)
  updateOrder(ctx: StateContext<OrdersStateModel>, { payload: { body, id } }: OrdersUpdate) {
    return this.ordersService.updateOrderBff(id, body).pipe(
      switchMap(() => ctx.dispatch(new OrdersGetDetailed({ id }))),
      switchMap(() => this.route.queryParamMap.pipe(first())),
      tap(queryParamMap => {
        ctx.setState(
          produce(draft => {
            if (queryParamMap.get('status')) {
              draft.entities = draft.entities.filter(entity => entity.id !== id);
            } else {
              draft.entities = draft.entities.map(entity =>
                entity.id === id
                  ? {
                      ...entity,
                      status: body.type as unknown as OrderStatus,
                    }
                  : entity,
              );
            }
          }),
        );
      }),
    );
  }

  @Action(OrdersUpdateInvoice)
  updateOrderInvoice(_: StateContext<OrdersStateModel>, { payload: { body, id } }: OrdersUpdateInvoice) {
    return this.ordersService.updateInvoice(id, body);
  }

  @Action(OrdersUploadInvoiceImage)
  uploadInvoiceImage(_: StateContext<OrdersStateModel>, { payload: { formData, id } }: OrdersUploadInvoiceImage) {
    return this.ordersService.uploadFile(formData, id);
  }

  @Action(OrdersDownloadDeliveryNote)
  downloadDeliveryNote(_: StateContext<OrdersStateModel>, { orderId }: OrdersDownloadDeliveryNote) {
    return this.ordersService.getDeliveryNote(orderId).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl);
      }),
    );
  }

  @Action(OrdersDownloadLPO)
  downloadLPO(_: StateContext<OrdersStateModel>, { orderId }: OrdersDownloadLPO) {
    return this.ordersService.getOrderPdf(orderId).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl);
      }),
    );
  }

  @Action(OrdersClose)
  closeOrder(ctx: StateContext<OrdersStateModel>, { payload: { id } }: OrdersClose) {
    return this.ordersService.closeOrderBff(id).pipe(
      switchMap(() => this.route.queryParamMap.pipe(first())),
      tap(queryParamMap => {
        ctx.setState(
          produce(draft => {
            if (queryParamMap.get('status')) {
              draft.entities = draft.entities.filter(entity => entity.id !== id);
            } else {
              draft.entities = draft.entities.map(entity =>
                entity.id === id
                  ? {
                      ...entity,
                      status: OrderStatus.Received,
                    }
                  : entity,
              );
            }
          }),
        );
      }),
    );
  }

  @Action(OrdersCloseMany)
  closeOrders(_: StateContext<OrdersStateModel>, { payload: { orders } }: OrdersCloseMany) {
    return this.ordersService.closeOrdersBff(orders);
  }

  @Action(ReturnOrderItems)
  returnOrderItems(_: StateContext<OrdersStateModel>, { payload: { id, body } }: ReturnOrderItems) {
    return this.ordersService.returnOrderItemsBff(id, body);
  }

  @Action(OrdersSetSequence)
  setOrderSequence(ctx: StateContext<OrdersStateModel>, { payload }: OrdersSetSequence) {
    ctx.setState(
      produce(draft => {
        draft.sequence = { ...draft.sequence, ...payload };
      }),
    );
  }

  @Action(OrdersGetUnlinked)
  getUnlinkedOrders(ctx: StateContext<OrdersStateModel>, { payload }: OrdersGetUnlinked) {
    const query = new Query<Order & OrderRequestProps>({
      filtering: [
        {
          by: 'status',
          op: 'in',
          match: [OrderStatus.Submitted, OrderStatus.Confirmed],
        },
        {
          by: 'supplier.id',
          op: 'eq',
          match: payload.supplierId,
        },
        {
          by: 'branch.id',
          op: 'eq',
          match: payload.locationId,
        },
      ],
      ordering: [
        {
          by: 'createdAt',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
      paging: {
        limit: 50,
        offset: 0,
      },
    });

    return this.ordersService
      .getOrdersPostBffV2(query)
      .pipe(tap(({ data }) => ctx.patchState({ unlinkedOrders: data })));
  }

  @Action(OrdersResetUnlinked)
  resetUnlinkedOrders(ctx: StateContext<OrdersStateModel>) {
    ctx.patchState({ unlinkedOrders: [] });
  }

  private getQuery(state: OrdersStateModel): Query<Order & OrderRequestProps> {
    const { branches, start, end, status, suppliers, preferred, lpoRestaurant, sorting } = state.filters;
    const orderStatus = status as OrderStatus;

    const qb = new QueryBuilder<Order & OrderRequestProps>({
      filtering: [],
      paging: { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: [
        {
          by: sorting ? (this.getSortingField(status, sorting) as keyof Order & OrderRequestProps) : 'createdAt',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailerId',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (state.requestMetadata.channelIds?.length) {
      qb.filtering.setFilter({
        by: 'channelId',
        match: state.requestMetadata.channelIds,
        op: 'in',
      });
    }

    if (lpoRestaurant) {
      qb.filtering.setFilter({
        by: 'number',
        match: lpoRestaurant,
        op: 'like',
      });

      return qb.build();
    }

    if (branches?.length) {
      qb.filtering.setFilter({
        by: 'branch.id',
        match: branches,
        op: 'in',
      });
    }

    if (suppliers?.length) {
      qb.filtering.setFilter({
        by: 'supplierId',
        match: suppliers,
        op: 'in',
      });
    }

    if (start && end) {
      if (start.toString() === end.toString()) {
        const nextDay = new Date();

        nextDay.setDate(nextDay.getDate() + 1);

        qb.filtering.withFiltering([
          { by: 'createdAt', op: 'lte', match: getShiftedDate(new Date(nextDay), this.#utcOffset()).getTime() },
          { by: 'createdAt', op: 'gte', match: getShiftedDate(new Date(Number(start)), this.#utcOffset()).getTime() },
        ]);
      } else {
        qb.filtering.withFiltering([
          { by: 'createdAt', op: 'lte', match: getShiftedDate(new Date(Number(end)), this.#utcOffset()).getTime() },
          { by: 'createdAt', op: 'gte', match: getShiftedDate(new Date(Number(start)), this.#utcOffset()).getTime() },
        ]);
      }
    }

    if (orderStatus) {
      if (orderStatus === OrderStatus.Received) {
        qb.filtering.setFilter({
          by: 'status',
          match: [OrderStatus.Received, OrderStatus.PartialReceived],
          op: 'in',
        });
      } else if (orderStatus === OrderStatus.Confirmed) {
        qb.filtering.setFilter({
          by: 'status',
          match: [OrderStatus.Confirmed, OrderStatus.Shipped],
          op: 'in',
        });
      } else {
        qb.filtering.setFilter({
          by: 'status',
          match: orderStatus,
          op: 'eq',
        });
      }
    }

    if (preferred) {
      qb.filtering.withGroup('or', [
        {
          by: 'channel.preferredType',
          match: PreferredType.RevenueSharing,
          op: 'eq',
        },
        {
          by: 'products.preferredType',
          match: PreferredType.RevenueSharing,
          op: 'eq',
        },
      ]);
    }

    return qb.build();
  }

  private getSortingField(status: string, field: string): string {
    return (status as OrderStatus) === OrderStatus.Received && field === 'total.ordered' ? 'total.received' : field;
  }
}
