import { IANATimezone } from '@supy.api/dictionaries';

import { getApiDetailsDecorator, getRetailerTimeZoneShiftedDate } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { SupplierReturnDocumentAttachment, SupplierReturnDocumentRequest } from '../../entities';

const ApiProperty = getApiDetailsDecorator<SupplierReturnDocumentRequest>();

export class SupplierReturnDocumentPayload {
  constructor(args: NonFunctionProperties<SupplierReturnDocumentPayload>) {
    this.number = args.number;
    this.returnDate = args.returnDate;
    this.attachments = args.attachments;
    this.ianaTimeZone = args.ianaTimeZone;
    this.utcOffset = args.utcOffset;
  }

  @ApiProperty() readonly number: string;
  @ApiProperty({ key: 'documentDate' }) readonly returnDate: Date;
  @ApiProperty({ key: 'files' }) attachments?: SupplierReturnDocumentAttachment[];

  readonly ianaTimeZone: IANATimezone;
  readonly utcOffset: number;

  serialize(): SupplierReturnDocumentRequest {
    return {
      number: this.number,
      files: this.attachments.map(({ path }) => path),
      documentDate: getRetailerTimeZoneShiftedDate(new Date(this.returnDate), this.ianaTimeZone, this.utcOffset),
    };
  }

  appendFiles(files: string[]): void {
    this.attachments = this.attachments.concat(files.map(file => ({ path: file, signedUrl: file })));
  }
}
