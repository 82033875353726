import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { SupplierReturnItemTaxRateResponse } from './supplier-return-item.model';

const ApiProperty = getApiDetailsDecorator<SupplierReturnItemTaxRateResponse>();

export abstract class SupplierReturnItemBaseTaxRate {
  protected constructor(args: NonFunctionProperties<SupplierReturnItemBaseTaxRate>) {
    this.name = args.name;
    this.taxCode = args.taxCode;
    this.rate = args.rate;
    this.amount = args.amount;
  }

  @ApiProperty() readonly name: string;
  @ApiProperty() readonly taxCode: string;
  @ApiProperty() readonly rate: number;
  @ApiProperty() readonly amount: number;
}

export class SupplierReturnItemTaxRate extends SupplierReturnItemBaseTaxRate {
  private constructor(args: NonFunctionProperties<SupplierReturnItemTaxRate>) {
    super(args);
  }

  static deserialize(data: SupplierReturnItemTaxRateResponse): SupplierReturnItemTaxRate {
    return new SupplierReturnItemTaxRate({
      name: data.name,
      taxCode: data.taxCode,
      rate: data.rate ?? 0,
      amount: data.amount,
    });
  }
}
