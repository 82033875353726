import { Order, OrderItem, OrderStatus } from '../core';

export function compareModifiedWithOrdered(order: Order): OrderItem[] {
  return order.ordered.map(item => {
    const modified = isItemModified(order, item);

    return modified ? { ...modified } : { ...item };
  });
}

export function isItemModified(order: Order, orderItem: OrderItem): OrderItem {
  return order?.modified?.find((item: OrderItem) => item.productId === orderItem.productId);
}

export function getOrdersProducts(orders: Order[]): Map<string, OrderItem[]> {
  return orders.reduce((acc, order) => {
    const orderReceivedProducts: Map<string, OrderItem> = order.received?.reduce(
      (acc, received) => acc.set(received.productId, received),
      new Map<string, OrderItem>(),
    );
    const orderModifiedProducts: Map<string, OrderItem> = order.modified?.reduce(
      (acc, modified) => acc.set(modified.productId, modified),
      new Map<string, OrderItem>(),
    );
    const orderOrderedQtys: Map<string, number> = order.ordered?.reduce(
      (acc, ordered) => acc.set(ordered.productId, ordered.quantity),
      new Map<string, number>(),
    );
    const orderProducts = order.status === OrderStatus.Received ? order.received : order.ordered;
    const products = sortOrderItems<OrderItem>(
      orderProducts
        .map(product => {
          return orderModifiedProducts.has(product.productId)
            ? { ...product, ...orderModifiedProducts.get(product.productId) }
            : product;
        })
        .map(product =>
          orderReceivedProducts.has(product.productId)
            ? { ...product, ...orderReceivedProducts.get(product.productId) }
            : product,
        )
        .map(product => {
          return { ...product, orderedQty: orderOrderedQtys.get(product.productId) };
        }),
    );

    acc.set(order.id, products);

    return acc;
  }, new Map<string, OrderItem[]>());
}

export function sortOrderItems<T extends OrderItem>(items: T[]): T[] {
  return items.sort((a, b) => {
    if (a.name.toLowerCase() && b.name.toLowerCase()) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    } else if (a.itemCode && b.itemCode) {
      return a.itemCode.localeCompare(b.itemCode);
    } else {
      return 1;
    }
  });
}
