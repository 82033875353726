import { IANATimezone } from '@supy.api/dictionaries';

import { getApiDetailsDecorator, getDateInTimeZone, LocalFile } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { SupplierReturnDocumentAttachment, SupplierReturnDocumentResponse } from './supplier-return-document.model';

const ApiProperty = getApiDetailsDecorator<SupplierReturnDocumentResponse>();

export class SupplierReturnDocument {
  private constructor(args: NonFunctionProperties<SupplierReturnDocument>) {
    this.number = args.number;
    this.returnDate = args.returnDate;
    this.attachments = args.attachments;
    this.localFiles = args.localFiles;
  }

  @ApiProperty() readonly number: string;
  @ApiProperty({ key: 'documentDate' }) readonly returnDate: Date;
  @ApiProperty() readonly attachments: SupplierReturnDocumentAttachment[];
  readonly localFiles: LocalFile[];

  static deserialize(
    data: SupplierReturnDocumentResponse,
    {
      ianaTimezone,
    }: {
      readonly ianaTimezone: IANATimezone;
    },
  ): SupplierReturnDocument {
    return new SupplierReturnDocument({
      number: data.number,
      returnDate: getDateInTimeZone(data.documentDate, ianaTimezone),
      attachments: data.attachments ?? [],
      localFiles: [],
    });
  }
}
