import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IgxGridModule, IgxRadioModule } from '@infragistics/igniteui-angular';

import { SupyCommonModule } from '@supy/common';
import {
  AvatarModule,
  ButtonModule,
  CheckboxModule,
  ComboboxModule,
  CounterInputModule,
  DialogModule,
  DropdownModule,
  FiltersModule,
  GridModule,
  GridPocModule,
  IconModule,
  InputModule,
  LoaderModule,
  LoadingOverlayModule,
  SearchInputModule,
  SelectModule,
  SeparatorModule,
  StatusBadgeModule,
  TabViewSelectModule,
  TextareaModule,
} from '@supy/components';

import {
  AddOrderItemsDialogComponent,
  CreateItemDialogComponent,
  HeaderLanguageSelectCellRendererComponent,
  OrderIconStatusBadgeComponent,
  OrderItemsFooterComponent,
  OrderItemsGridComponent,
  OrdersListComponent,
  OrderStatusBadgeComponent,
  OrderStatusComponent,
  PredefinedRejectionDialogComponent,
  RejectionDialogComponent,
} from './components';

const COMPONENTS = [
  OrdersListComponent,
  OrderItemsGridComponent,
  OrderStatusComponent,
  OrderIconStatusBadgeComponent,
  PredefinedRejectionDialogComponent,
  RejectionDialogComponent,
  AddOrderItemsDialogComponent,
  OrderStatusBadgeComponent,
  CreateItemDialogComponent,
  OrderItemsFooterComponent,
  HeaderLanguageSelectCellRendererComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    IgxGridModule,
    ReactiveFormsModule,
    CommonModule,
    SupyCommonModule,
    ButtonModule,
    IconModule,
    RouterModule,
    StatusBadgeModule,
    LoadingOverlayModule,
    InputModule,
    GridModule,
    SelectModule,
    DropdownModule,
    DialogModule,
    TextareaModule,
    SeparatorModule,
    CheckboxModule,
    AvatarModule,
    SearchInputModule,
    CounterInputModule,
    LoaderModule,
    ComboboxModule,
    IgxRadioModule,
    FiltersModule,
    TabViewSelectModule,
    GridPocModule,
  ],
  exports: [...COMPONENTS],
})
export class OrdersListModule {}
