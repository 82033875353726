import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { GrnItemTaxRateResponse } from './grn-item.model';

const ApiProperty = getApiDetailsDecorator<GrnItemTaxRateResponse>();

export abstract class GrnBaseItemTaxRate {
  protected constructor(args: NonFunctionProperties<GrnBaseItemTaxRate>) {
    this.name = args.name;
    this.taxCode = args.taxCode;
    this.rate = args.rate ?? 0;
    this.amount = args.amount;
  }

  @ApiProperty() readonly name: string;
  @ApiProperty() readonly taxCode: string;
  @ApiProperty() readonly rate: number;
  @ApiProperty() readonly amount: number;
}

export class GrnItemTaxRate extends GrnBaseItemTaxRate {
  private constructor(args: NonFunctionProperties<GrnItemTaxRate>) {
    super(args);
  }

  static deserialize(data: GrnItemTaxRateResponse): GrnItemTaxRate {
    return new GrnItemTaxRate({
      name: data.name,
      taxCode: data.taxCode,
      rate: data.rate,
      amount: data.amount,
    });
  }
}
