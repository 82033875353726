import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { trackByProperty } from '@supy/common';
import { IconType } from '@supy/components';

export interface OrderDetailsWidget {
  readonly header: string;
  readonly headerIcon?: IconType;
  readonly content: string;
}

@Component({
  selector: 'supy-retailer-order-details-combined-widget[widgets]',
  templateUrl: './retailer-order-details-combined-widget.component.html',
  styleUrls: ['./retailer-order-details-combined-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsCombinedWidgetComponent {
  @Input() protected readonly isInitialLoading: boolean;
  @Input() protected readonly widgets: OrderDetailsWidget[];

  protected readonly trackBy = trackByProperty<OrderDetailsWidget>('header');
}
