import { inject, InjectionToken } from '@angular/core';

import { CommonConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const GRNS_URI = new InjectionToken<string>('GRNs BFF URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/grns`;
  },
});
