import { getApiDetailsDecorator, LocalFile } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  GrnBaseDocumentResponse,
  GrnDocumentAttachment,
  GrnDocumentResponse,
  GrnDocumentType,
  GrnSimpleDocumentResponse,
} from './grn-document.model';

const ApiProperty = getApiDetailsDecorator<GrnSimpleDocumentResponse | GrnDocumentResponse>();

export abstract class GrnBaseDocument {
  protected constructor(args: NonFunctionProperties<GrnBaseDocument>) {
    this.type = args.type;
    this.number = args.number;
    this.invoiceAmount = args.invoiceAmount;
    this.documentDate = args.documentDate;
    this.isPaid = args.isPaid;
    this.isPending = args.isPending;
  }

  @ApiProperty() readonly number: string;
  @ApiProperty() readonly type: GrnDocumentType;
  @ApiProperty() readonly invoiceAmount?: number;
  @ApiProperty() readonly documentDate?: Date;
  @ApiProperty() readonly isPaid?: boolean;
  @ApiProperty({ key: 'pending' }) readonly isPending?: boolean;

  protected static deserialize(data: GrnBaseDocumentResponse): NonFunctionProperties<GrnBaseDocument> {
    return {
      type: data.type ?? GrnDocumentType.Invoice,
      number: data.number,
      invoiceAmount: data.invoiceAmount ?? 0,
      documentDate: data.documentDate && new Date(data.documentDate),
      isPaid: data.isPaid ?? false,
      isPending: data.pending ?? false,
    };
  }
}

export class GrnSimpleDocument extends GrnBaseDocument {
  protected constructor(args: NonFunctionProperties<GrnSimpleDocument>) {
    super(args);

    this.files = args.files;
  }

  @ApiProperty() readonly files?: string[];

  static deserialize(data: GrnSimpleDocumentResponse): GrnSimpleDocument {
    return new GrnSimpleDocument({
      ...super.deserialize(data),
      files: data.files,
    });
  }
}

export class GrnDocument extends GrnBaseDocument {
  protected constructor(args: NonFunctionProperties<GrnDocument>) {
    super(args);

    this.paymentDueDate = args.paymentDueDate;
    this.attachments = args.attachments ?? [];
    this.signedPdfUrl = args.signedPdfUrl;
    this.localFiles = args.localFiles ?? [];
  }

  @ApiProperty() readonly paymentDueDate?: Date;
  @ApiProperty() readonly signedPdfUrl?: string;
  @ApiProperty() readonly attachments: GrnDocumentAttachment[];

  readonly localFiles?: LocalFile[];

  static deserialize(data: GrnDocumentResponse): GrnDocument {
    return new GrnDocument({
      ...super.deserialize(data),
      paymentDueDate: data.paymentDueDate && new Date(data.paymentDueDate),
      signedPdfUrl: data.signedPdfUrl,
      attachments: data.attachments,
    });
  }
}
