import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IdType, IQueryResponse, Query } from '@supy/common';

import { SUPPLIER_RETURNS_URI } from '../../config';
import {
  SupplierReturnCreateRequest,
  SupplierReturnDownloadPdfResponse,
  SupplierReturnGenerateDocumentNumberRequest,
  SupplierReturnGenerateDocumentNumberResponse,
  SupplierReturnLockManyRequest,
  SupplierReturnPostManyRequest,
  SupplierReturnPostManyResponse,
  SupplierReturnQueryProps,
  SupplierReturnResponse,
  SupplierReturnUpdateRequest,
  SupplierReturnUploadResponse,
} from '../../core';

@Injectable()
export class SupplierReturnsService {
  readonly #httpClient = inject(HttpClient);
  readonly #uri = inject(SUPPLIER_RETURNS_URI);

  get(id: string): Observable<SupplierReturnResponse> {
    return this.#httpClient.get<SupplierReturnResponse>(`${this.#uri}/${id}`);
  }

  getMany(query: Query<SupplierReturnQueryProps>): Observable<IQueryResponse<SupplierReturnResponse>> {
    return this.#httpClient.get<IQueryResponse<SupplierReturnResponse>>(this.#uri, { params: query.toQueryParams() });
  }

  create(body: SupplierReturnCreateRequest): Observable<IdType> {
    return this.#httpClient.post<IdType>(this.#uri, body);
  }

  update(id: string, body: SupplierReturnUpdateRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/${id}`, body);
  }

  post(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/post`, null);
  }

  postMany(body: SupplierReturnPostManyRequest): Observable<SupplierReturnPostManyResponse> {
    return this.#httpClient.post<SupplierReturnPostManyResponse>(`${this.#uri}/post`, body);
  }

  lock(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/lock`, null);
  }

  lockMany(body: SupplierReturnLockManyRequest): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/lock`, body);
  }

  unlock(id: string): Observable<void> {
    return this.#httpClient.post<void>(`${this.#uri}/${id}/unlock`, null);
  }

  uploadFile(file: FormData, retailerId: string): Observable<SupplierReturnUploadResponse> {
    return this.#httpClient.post<SupplierReturnUploadResponse>(`${this.#uri}/upload`, file, {
      params: {
        retailerId,
      },
    });
  }

  generateDocumentNumber(
    body: SupplierReturnGenerateDocumentNumberRequest,
  ): Observable<SupplierReturnGenerateDocumentNumberResponse> {
    return this.#httpClient.post<SupplierReturnGenerateDocumentNumberResponse>(`${this.#uri}/generate`, body);
  }

  downloadPdf(id: string): Observable<SupplierReturnDownloadPdfResponse> {
    return this.#httpClient.get<SupplierReturnDownloadPdfResponse>(`${this.#uri}/${id}/download`);
  }
}
