import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { IconType } from '@supy/components';

@Component({
  selector: 'supy-retailer-order-details-widget',
  templateUrl: './retailer-order-details-widget.component.html',
  styleUrls: ['./retailer-order-details-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsWidgetComponent {
  @Input() protected readonly isInitialLoading: boolean;
  @Input() protected readonly header: string;
  @Input() protected readonly content: string;
  @Input() protected readonly disabled: boolean;
  @Input() protected readonly headerIcon: IconType;

  @HostBinding('style.minHeight.rem')
  @Input()
  protected readonly minHeight = '5';
}
