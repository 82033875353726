import { Currency } from '@supy.api/dictionaries';

import { PartyInfo, PreferredType, User } from '@supy/common';
import { Country } from '@supy/countries';

export enum ProcurementOrderStatus {
  Confirmed = 'confirmed',
  Drafted = 'draft',
  PartiallyReceived = 'partial-received',
  Received = 'received',
  Rejected = 'rejected',
  Submitted = 'submitted',
}

export enum ProcurementOrderRequestType {
  Commented = 'comment',
  Confirmed = 'confirmed',
  Discarded = 'discarded',
  Modified = 'modified',
  PartiallyReceived = 'partial-received',
  Received = 'received',
  Submitted = 'submitted',
}

export interface ProcurementOrderResponse {
  readonly channelId: string;
  readonly channelPreferredType: PreferredType;
  readonly comment: string | null;
  readonly country: Country;
  readonly createdAt: string;
  readonly deliveryDate: string;
  readonly id: string;
  readonly modified: ProcurementOrderItemResponse[];
  readonly modifiedTotal: number;
  readonly number: string;
  readonly ordered: ProcurementOrderItemResponse[];
  readonly orderedTotal: number;
  readonly partyInfo: PartyInfo;
  readonly received: ProcurementOrderItemResponse[];
  readonly receivedTotal: number;
  readonly retailerId: string;
  readonly status: ProcurementOrderStatus;
  readonly supplierId: string;
  readonly updates: ProcurementOrderUpdateResponse[];
  readonly vat: number;
}

export interface ProcurementOrderItemResponse {
  readonly currency: Currency;
  readonly name: string;
  readonly name_ar: string | null;
  readonly preferredType: PreferredType;
  readonly price: number;
  readonly productId: string;
  readonly quantity: number;
  readonly unit: string;
}

export interface ProcurementOrderUpdateResponse {
  readonly comment: string | null;
  readonly createdAt: string;
  readonly orderId: string;
  readonly status: ProcurementOrderRequestType;
  readonly user: User;
  readonly userId: string;
}

export interface ProcurementOrderQueryProps {
  readonly 'branch.id': string;
  readonly createdAt: number;
  readonly number: string;
  readonly retailerId: string;
  readonly status: string;
  readonly supplierId: string;
}

export interface ProcurementOrdersResponseMetadata {
  readonly count: number;
  readonly total: number;
}

export interface ProcurementOrderRequestMetadata {
  readonly page: number;
  readonly limit: number;
}

export interface ProcurementOrderItemRequest {
  readonly productId: string;
  readonly quantity: number;
}

export interface ProcurementOrderRequest<T extends ProcurementOrderRequestType> {
  readonly type: T;
}

export type ProcurementOrderDiscardRequest = ProcurementOrderRequest<ProcurementOrderRequestType.Discarded>;

export interface ProcurementOrderSubmitRequest extends ProcurementOrderRequest<ProcurementOrderRequestType.Submitted> {
  readonly ordered: ProcurementOrderItemRequest[];
}

export interface ProcurementOrderUpdateRequest extends ProcurementOrderRequest<ProcurementOrderRequestType.Received> {
  readonly received: ProcurementOrderItemRequest[];
}

export interface ProcurementOrderReceiveRequest
  extends ProcurementOrderRequest<
    ProcurementOrderRequestType.Received | ProcurementOrderRequestType.PartiallyReceived
  > {
  readonly received: ProcurementOrderItemRequest[];
}

export interface ProcurementOrderAddCommentRequest
  extends ProcurementOrderRequest<ProcurementOrderRequestType.Commented> {
  readonly comment: string;
}
