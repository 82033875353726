import { Currency } from '@supy.api/dictionaries';

import { PreferredType } from '@supy/common';
import { TaxRate } from '@supy/settings';

export class OrderItem {
  readonly productId: string;
  readonly orderedQty?: number;
  readonly quantity: number;
  // TODO: the fields below are optional because of backward compatibility
  readonly price?: number;
  readonly name?: string;
  readonly name_ar?: string;
  readonly unit?: string;
  readonly itemCode?: string;
  readonly currency?: Currency;
  readonly preferredType?: PreferredType;
  constructor(args: Partial<OrderItem>) {
    this.productId = args.productId;
    this.quantity = args.quantity;
    this.price = args.price;
    this.name = args.name;
    this.unit = args.unit;
    this.itemCode = args.itemCode;
    this.currency = args.currency;
    this.preferredType = args.preferredType;
  }
}

export interface OrderItemWithQtyDetail extends OrderItem {
  readonly receivedQty?: number;
  readonly modifiedQty?: number;
  readonly supplierItemName?: string;
  readonly appliedTax?: TaxRate;
}
