import { NonFunctionProperties } from '@supy/core';

import { GrnBaseItemTaxRate, GrnItemTaxRateRequest } from '../../entities';

export class GrnItemTaxRatePayload extends GrnBaseItemTaxRate {
  constructor(args: NonFunctionProperties<GrnItemTaxRatePayload>) {
    super(args);
    this.itemHandlingFee = args.itemHandlingFee ?? 0;
    this.invoicePrice = args.invoicePrice;
    this.documentQuantity = args.documentQuantity;
    this.adjustmentRate = args.adjustmentRate;
  }

  readonly itemHandlingFee: number;
  readonly invoicePrice: number;
  readonly documentQuantity: number;
  readonly adjustmentRate: number;

  serialize(): GrnItemTaxRateRequest {
    return {
      name: this.name,
      rate: this.rate,
      taxCode: this.taxCode,
      amount:
        (this.rate / 100) *
        (this.invoicePrice + this.itemHandlingFee) *
        this.documentQuantity *
        ((100 - this.adjustmentRate) / 100),
    };
  }
}
