import { getApiDetailsDecorator, IdType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { getLocalizedName } from '@supy/settings';

import { PercentageValue } from '../order';
import { GrnChannelItem } from './grn-channel-item.entity';
import { GrnCreditNote } from './grn-credit-note.entity';
import { GrnCreditNoteType } from './grn-credit-note.model';
import {
  GrnCreditNoteItemResponse,
  GrnItemPrices,
  GrnItemQuantities,
  GrnItemResponse,
  GrnItemType,
} from './grn-item.model';
import { GrnItemTaxRate } from './grn-item-tax-rate.entity';

const ApiProperty = getApiDetailsDecorator<GrnItemResponse | GrnCreditNoteItemResponse>();

export abstract class GrnBaseItem {
  protected constructor(args: NonFunctionProperties<GrnBaseItem>) {
    this.type = args.type;
    this.quantities = args.quantities;
    this.prices = args.prices;
    this.channelItem = args.channelItem;
    this.appliedTax = args.appliedTax;
    this.comment = args.comment;
  }

  @ApiProperty() readonly type: GrnItemType;
  @ApiProperty() readonly quantities: GrnItemQuantities;
  @ApiProperty() readonly prices: GrnItemPrices;
  @ApiProperty() readonly channelItem: GrnChannelItem;
  @ApiProperty() readonly appliedTax?: GrnItemTaxRate;
  @ApiProperty() readonly comment?: string;
}

export class GrnItem extends GrnBaseItem {
  private constructor(args: NonFunctionProperties<GrnItem>) {
    super(args);

    this.id = args.id;
    this.invoiceAdjustment = args.invoiceAdjustment;
    this.partiallyReceived = args.partiallyReceived;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly invoiceAdjustment?: PercentageValue;
  @ApiProperty() readonly partiallyReceived?: boolean;

  static deserialize(data: GrnItemResponse): GrnItem {
    return new GrnItem({
      id: data.id,
      type: GrnItemType.Item,
      quantities: data.quantities,
      prices: data.prices,
      channelItem: data.channelItem && GrnChannelItem.deserialize(data.channelItem),
      appliedTax: data.appliedTax && GrnItemTaxRate.deserialize(data.appliedTax),
      invoiceAdjustment: data.invoiceAdjustment,
      partiallyReceived: data.partiallyReceived ?? false,
      comment: data.comment,
    });
  }

  static deserializeList(data: GrnItemResponse[]): GrnItem[] {
    return data.map(item => this.deserialize(item));
  }

  static calculateAppliedTaxAmount({
    quantities,
    prices,
    appliedTax,
    adjustmentRate,
  }: Pick<GrnItem, 'quantities' | 'prices' | 'appliedTax'> & {
    readonly adjustmentRate: number;
  }): number {
    return (
      (appliedTax?.rate / 100) *
      (prices.invoice + (prices.itemHandlingFee ?? 0)) *
      quantities.document *
      ((100 - adjustmentRate) / 100)
    );
  }

  static calculateTotal(data: GrnItem[]): number {
    return data.reduce<number>(
      (acc, { quantities, prices }) => acc + quantities.document * (prices.invoice + (prices.itemHandlingFee ?? 0)),
      0,
    );
  }
}

export class GrnCreditNoteItem extends GrnBaseItem {
  private constructor(args: NonFunctionProperties<GrnCreditNoteItem>) {
    super(args);

    this.id = args.id;
    this.type = GrnItemType.Credit;
    this.allocatedCreditNote = args.allocatedCreditNote;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly type: GrnItemType.Credit;
  @ApiProperty() readonly allocatedCreditNote: IdType;

  static deserialize(data: GrnCreditNoteItemResponse): GrnCreditNoteItem {
    const creditType = data.quantities.document < 0 ? 'Quantity' : 'Price';

    return new GrnCreditNoteItem({
      id: data.id,
      type: GrnItemType.Credit,
      quantities: data.quantities,
      prices: data.prices,
      comment: data.comment,
      allocatedCreditNote: data.allocatedCreditNote,
      appliedTax: data.appliedTax && GrnItemTaxRate.deserialize(data.appliedTax),
      channelItem:
        data.channelItem &&
        GrnChannelItem.deserialize({
          ...data.channelItem,
          name: { en: `${creditType} Credit - ${getLocalizedName(data.channelItem.name)}` },
        }),
    });
  }

  static fromCreditNote(data: GrnCreditNote): GrnCreditNoteItem {
    return new GrnCreditNoteItem({
      id: crypto.randomUUID(),
      type: GrnItemType.Credit,
      quantities: {
        document: data.creditType === GrnCreditNoteType.Quantity ? -data.quantity : data.quantity,
        received: 0,
        ordered: 0,
      },
      prices: {
        invoice: data.creditType === GrnCreditNoteType.Price ? -data.price : data.price,
        item: data.price,
      },
      channelItem:
        data.item &&
        GrnChannelItem.deserialize({
          ...data.item,
          name: {
            en: `${data.creditType.charAt(0).toUpperCase() + data.creditType.slice(1)} Credit - ${getLocalizedName(
              data.item.name,
            )}`,
          },
        }),
      appliedTax: data.appliedTax && GrnItemTaxRate.deserialize(data.appliedTax),
      comment: data.comment,
      allocatedCreditNote: {
        id: data.id,
      },
    });
  }

  static calculateTotal(data: GrnCreditNoteItem[]): number {
    return data.reduce<number>((acc, { quantities, prices }) => acc + quantities.document * prices.invoice, 0);
  }
}
