import { FilterConfig } from '@supy/components';

export interface RetailerAddOrderItemsDrawerMappedFilters {
  readonly search: string;
}

export function getRetailerAddOrderItemsFiltersConfig(): FilterConfig {
  return {
    search: {
      placeholder: $localize`:@@orders.searchByPackageName:Search by package item name`,
      value: null,
    },
  };
}
