<div class="supy-orders-list">
  <div class="supy-orders-list__body">
    <supy-loading-overlay igxPreventDocumentScroll *ngIf="isLoading"></supy-loading-overlay>
    <supy-grid
      class="supy-orders-list__grid"
      cellSelection="none"
      [data]="orders$ | async"
      [minRowHeight]="54"
      primaryKey="id"
      [rowSelection]="gridRowSelection"
      [clipboardOptions]="clipboardOptions"
      [emptyGridTemplate]="emptyTemplate"
      (rowExpanded)="onToggleRow($event)"
      [paginationMode]="paginationMode"
      [page]="requestMetadata?.page"
      [perPage]="requestMetadata?.limit"
      [prevPageDisabled]="requestMetadata?.page === 0"
      [nextPageDisabled]="responseMetadata?.count < requestMetadata?.limit"
      (pageChange)="pageChange?.emit($event)"
    >
      <supy-grid-column *ngIf="rowSelection === 'multiple'" width="5%">
        <ng-template supyGridCellHeader let-column="column">
          <supy-checkbox
            [checked]="areAllItemsSelected()"
            [indeterminate]="anyItemSelected() && !areAllItemsSelected()"
            (checkedChange)="onItemHeadCheck($event)"
          >
          </supy-checkbox>
        </ng-template>
        <ng-template supyGridCell let-rowData="rowData">
          <div class="supplier-catalog__item-grid-row-selector">
            <supy-checkbox
              *ngIf="canToggleSelection(rowData)"
              [checked]="selectedOrders.has(rowData.id)"
              (checkedChange)="onItemCheck(rowData)"
            ></supy-checkbox>
          </div>
        </ng-template>
      </supy-grid-column>

      <supy-grid-column
        *ngFor="let column of columns; trackBy: trackColumnsByIndex"
        [sortable]="column.sortable"
        [header]="column.header"
        [field]="column.field"
        [width]="column.width"
        [ngSwitch]="column.type"
      >
        <ng-template supyGridCell let-value let-cell="cell" let-rowData="rowData">
          <ng-container *ngSwitchCase="listColumnType.LPO">
            <ng-container
              *ngTemplateOutlet="lpoTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.ChannelOrderIndex">
            <ng-container
              *ngTemplateOutlet="channelOrderIndexTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.Branch">
            <ng-container
              *ngTemplateOutlet="branchTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.Supplier">
            <ng-container
              *ngTemplateOutlet="supplierTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.RequestDate">
            <ng-container
              *ngTemplateOutlet="requestDateTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.DeliveryDate">
            <ng-container
              *ngTemplateOutlet="deliveryDateTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.ItemsCount">
            <ng-container
              *ngTemplateOutlet="itemsCountTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.OrderedTotal">
            <ng-container
              *ngTemplateOutlet="orderedTotalTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.ReceivedTotal">
            <ng-container
              *ngTemplateOutlet="receivedTotalTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="listColumnType.Status">
            <ng-container
              *ngTemplateOutlet="statusTemplate; context: { value: value, cell: cell, rowData: rowData }"
            ></ng-container>
          </ng-container>
        </ng-template>
      </supy-grid-column>

      <ng-template supyGridDetail let-dataItem>
        <div class="supy-orders-list__expanded-container">
          <div class="supy-orders-list__order-basic">
            <div class="supy-orders-list__column">
              <span class="supy-orders-list__label">{{
                dataItem.status === orderStatus.Draft ? 'Drafted by' : 'Submitted by'
              }}</span>
              <span>{{ getUpdatedBy(dataItem) }}</span>
            </div>
            <div class="supy-orders-list__column">
              <span class="supy-orders-list__label">Order Notes</span>
              <span>{{ dataItem.comment }}</span>
            </div>
          </div>
          <supy-separator type="solid" class="supy-orders-list__separator"></supy-separator>

          <div class="supy-orders-list__expanded-row" (keydown)="$event.stopPropagation()">
            <span *ngIf="canConfirmRejectOrder || !isAdmin" class="supy-orders-list__expanded-row--w-10 font-bold"
              >Item Code</span
            >
            <span
              *ngIf="
                (canOrderBePartiallyReceived(dataItem) && isEditingReceivedItems) ||
                dataItem.status === orderStatus.PartialReceived
              "
              class="supy-orders-list__expanded-row--w-5 font-bold"
              >Received</span
            >
            <span class="supy-orders-list__expanded-row--w-20 font-bold">Item Name</span>
            <span class="supy-orders-list__expanded-row--w-10 font-bold">Ordered</span>
            <span *ngIf="showModified(dataItem)" class="supy-orders-list__expanded-row--w-10 font-bold">Modified</span>
            <span
              *ngIf="
                (canOrderBePartiallyReceived(dataItem) && isEditingReceivedItems) ||
                dataItem.status === orderStatus.Received
              "
              class="supy-orders-list__expanded-row--w-10 font-bold"
              >Delivered</span
            >
            <div class="supy-orders-list__expanded-row--w-10 font-bold">
              Rate <span class="supy-orders-list__label--grey font-bold">{{ getCurrency(dataItem.countryId) }}</span>
            </div>
            <div class="supy-orders-list__expanded-row--w-10 font-bold">
              Total <span class="supy-orders-list__label--grey font-bold">{{ getCurrency(dataItem.countryId) }}</span>
            </div>
            <span
              class="supy-orders-list__expanded-row--w-10 font-bold"
              *ngIf="dataItem.status === orderStatus.Received"
            >
              Received Total</span
            >
            <span class="supy-orders-list__expanded-row--w-10 font-bold" *ngIf="canShowSupplierAction(dataItem)">
            </span>
          </div>

          <div *ngIf="productsLoading" class="supy-orders-list__expanded-row--loading">
            <supy-loader color="primary"></supy-loader>
          </div>

          <ng-container *ngIf="!productsLoading">
            <ng-container *ngIf="orderItems$ | async as orderItems">
              <ng-container *ngFor="let orderItem of orderItems.get(dataItem.id); index as i">
                <div
                  class="supy-orders-list__expanded-row"
                  [class.supy-orders-list__out-of-stock]="outOfStock(orderItem)"
                >
                  <div *ngIf="canConfirmRejectOrder || !isAdmin" class="supy-orders-list__expanded-row--w-10">
                    {{ orderItem.itemCode }}
                  </div>
                  <div
                    *ngIf="
                      (canOrderBePartiallyReceived(dataItem) && isEditingReceivedItems) ||
                      dataItem.status === orderStatus.PartialReceived
                    "
                    class="supy-orders-list__expanded-row--w-5"
                  >
                    <supy-checkbox
                      [disabled]="
                        changedPartialReceivedItems.get(orderItem.productId)?.sealed || !isEditingReceivedItems
                      "
                      [checked]="isOrderItemPartiallyChecked(dataItem, orderItem)"
                      (checkedChange)="onCheckPartialReceivedOrderItem($event, orderItem)"
                    ></supy-checkbox>
                  </div>
                  <div class="supy-orders-list__expanded-row--w-20">
                    <supy-icon
                      *ngIf="showPreferredFlag && orderItem.preferredType === preferredType.RevenueSharing"
                      size="small"
                      name="star"
                      color="primary"
                    ></supy-icon>
                    {{ orderItem.name }}
                  </div>
                  <div class="supy-orders-list__expanded-row--w-10">
                    <!-- FIXME: clean up convoluted conditions -->
                    <supy-input
                      *ngIf="
                        (canShowSupplierAction(dataItem) && !outOfStock(orderItem)) ||
                          (!(canOrderBePartiallyReceived(dataItem) || dataItem.status === orderStatus.Received) &&
                            isEditingReceivedItems);
                        else textSpan
                      "
                      #quantityInput
                      type="number"
                      [step]="0.001"
                      [value]="orderItem.orderedQty"
                      (valueChange)="onChangeReceivedOrderItem(orderItem, $event, quantityInput, 'quantity')"
                    ></supy-input>

                    <ng-template #textSpan>
                      {{ orderItem.orderedQty | number: '0.1-2' }}
                    </ng-template>
                  </div>
                  <div *ngIf="showModified(dataItem)" class="supy-orders-list__expanded-row--w-10">
                    {{ orderItem.quantity | number: '0.1-2' }}
                  </div>
                  <div
                    *ngIf="
                      (canOrderBePartiallyReceived(dataItem) && isEditingReceivedItems) ||
                      dataItem.status === orderStatus.Received
                    "
                    class="supy-orders-list__expanded-row--w-10"
                  >
                    <supy-input
                      *ngIf="
                        isEditingReceivedItems &&
                          ((changedPartialReceivedItems.get(orderItem.productId)?.checked &&
                            !changedPartialReceivedItems.get(orderItem.productId)?.sealed) ||
                            dataItem.status === orderStatus.Received);
                        else quantityText
                      "
                      #deliveredInput
                      type="number"
                      [step]="0.001"
                      [value]="orderItem.quantity"
                      (valueChange)="onChangePartialOrderItem(orderItem, $event, deliveredInput, 'quantity')"
                    ></supy-input>

                    <ng-template #quantityText>
                      {{ orderItem.quantity | number: '0.1-2' }}
                    </ng-template>
                  </div>
                  <div class="supy-orders-list__expanded-row--w-10">
                    <supy-input
                      *ngIf="
                        isEditingReceivedItems &&
                          changedPartialReceivedItems.get(orderItem.productId)?.checked &&
                          !changedPartialReceivedItems.get(orderItem.productId)?.sealed;
                        else priceText
                      "
                      #priceInput
                      type="number"
                      [step]="0.001"
                      [value]="orderItem.price"
                      (valueChange)="onItemPriceChange(dataItem, orderItem, $event, priceInput)"
                    ></supy-input>
                    <ng-template #priceText>
                      {{ orderItem.price | number: '0.2-2' }}
                    </ng-template>
                  </div>
                  <div class="supy-orders-list__expanded-row--w-10">
                    {{ getOrderedTotal(dataItem, orderItem.productId) | number: '0.2-2' }}
                  </div>
                  <div *ngIf="dataItem.status === orderStatus.Received" class="supy-orders-list__expanded-row--w-10">
                    {{ orderItem.price * orderItem.quantity | number: '0.2-2' }}
                  </div>
                </div>

                <div *ngIf="canShowSupplierAction(dataItem)">
                  <ng-container *ngIf="outOfStock(orderItem); else inStock">
                    <supy-icon
                      class="cursor--pointer"
                      size="small"
                      name="undo"
                      color="error"
                      title="Undo"
                      (click)="markInStock(dataItem, orderItem.productId)"
                    ></supy-icon>
                  </ng-container>

                  <ng-template #inStock>
                    <supy-icon
                      class="cursor--pointer"
                      size="small"
                      name="close"
                      color="error"
                      title="Out of stock"
                      (click)="onModifyOrder(dataItem.id, orderItem.productId)"
                    ></supy-icon>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <div class="supy-orders-list__separator-container">
            <div class="supy-orders-list__flex">
              <ng-container *ngIf="dataItem?.invoice?.numbers?.length && showInvoices">
                <supy-icon name="invoice" size="small"></supy-icon>
                <span *ngFor="let number of dataItem?.invoice?.numbers; trackBy: trackByIndex; let last = last"
                  >{{ number }}<span *ngIf="!last">&nbsp;,</span></span
                >
              </ng-container>
            </div>
            <div class="supy-orders-list__total-container">
              <div class="supy-orders-list__flex-container">
                <span>Sub-Total</span>
                <div>
                  {{ dataItem.country.currency }}
                  {{
                    (dataItem.status === orderStatus.Received
                      ? dataItem.receivedTotal
                      : dataItem.modifiedTotal > 0
                        ? dataItem.modifiedTotal
                        : dataItem.orderedTotal
                    ) | supyPrecision: currencyPrecision
                  }}
                </div>
              </div>

              <div class="supy-orders-list__flex-container">
                <span>Tax ({{ dataItem.vat }}%)</span>
                <div>
                  {{ dataItem.country.currency }}
                  {{
                    (dataItem.vat / 100) *
                      (dataItem.status === orderStatus.Received
                        ? dataItem.receivedTotal
                        : dataItem.modifiedTotal > 0
                          ? dataItem.modifiedTotal
                          : dataItem.orderedTotal) | supyPrecision: currencyPrecision
                  }}
                </div>
              </div>
              <div class="supy-orders-list__flex-container">
                <span class="supy-orders-list__total-title">Total</span>
                <div class="supy-orders-list__total-title">
                  {{ dataItem.country.currency }}
                  {{ getOrderTotalAfterVAT(dataItem) | supyPrecision: currencyPrecision }}
                </div>
              </div>
            </div>
          </div>
          <div class="supy-orders-list__scanned" *ngIf="dataItem?.invoice?.images?.length && showInvoices">
            <supy-icon name="picture" size="small"></supy-icon>
            <span>{{ dataItem?.invoice?.images?.length }}</span>
          </div>
          <div class="supy-orders-list__actions-container">
            <div *ngIf="canEditOrder" class="supy-orders-list__actions">
              <supy-button
                *ngIf="dataItem.status === orderStatus.Draft"
                type="button"
                class="supy-orders-list__actions--danger-btn"
                color="secondary"
                name="discard-draft"
                (buttonClick)="onDeleteDraft(dataItem)"
              >
                <supy-icon name="delete"></supy-icon>
                <span>Discard Draft</span>
              </supy-button>

              <supy-button
                *ngIf="
                  (dataItem.status === orderStatus.Received || canOrderBePartiallyReceived(dataItem)) &&
                  !isEditingReceivedItems
                "
                type="button"
                color="secondary"
                [routerLink]="['/channels', dataItem.retailerId, dataItem.channelId, 'place']"
                [queryParams]="{ orderId: dataItem.id, repeat: true }"
                queryParamsHandling="merge"
                name="repeat-order"
              >
                <span>Repeat Order</span>
              </supy-button>
              <supy-button
                *ngIf="
                  (dataItem.status === orderStatus.Submitted || dataItem.status === orderStatus.Confirmed) &&
                  isEditingReceivedItems
                "
                color="secondary"
                name="add-items"
                class="supy-orders-list__actions--primary-btn"
                (buttonClick)="onAddItemsClick(dataItem)"
                type="button"
              >
                <supy-icon
                  [name]="(isAddItemsLoading$ | async) ? 'loading' : 'plus-flat'"
                  size="small"
                  color="primary"
                ></supy-icon>
                <span>Add Items</span>
              </supy-button>
              <supy-button
                *ngIf="canOrderBePartiallyReceived(dataItem)"
                type="button"
                color="secondary"
                name="edit-receive"
                class="supy-orders-list__actions--primary-btn"
                [disabled]="isEditingReceivedItems && isPartialReceiveButtonDisabled"
                (buttonClick)="isEditingReceivedItems ? onReceiveClick(dataItem) : onEditAndReceiveClick(dataItem)"
              >
                <supy-icon
                  [name]="isEditingReceivedItems ? 'check-circle' : 'check'"
                  size="extra-small"
                  color="primary"
                ></supy-icon>
                <span>{{ isEditingReceivedItems ? 'Receive' : 'Edit & Receive' }}</span>
              </supy-button>
              <supy-button
                *ngIf="dataItem.status === orderStatus.Draft"
                type="button"
                [routerLink]="['/channels', dataItem.retailerId, dataItem.channelId, 'place']"
                [queryParams]="{ orderId: dataItem.id }"
                queryParamsHandling="merge"
                color="secondary"
                name="edit-order"
              >
                <supy-icon name="edit"></supy-icon>
                <span>Edit Order</span>
              </supy-button>
              <supy-button
                *ngIf="dataItem.status === orderStatus.Draft"
                type="button"
                color="primary"
                name="submit-order"
                [disabled]="submitDisabled?.[dataItem.id]"
                (buttonClick)="onSubmitOrder(dataItem)"
              >
                <span>Submit Order</span>
              </supy-button>
              <supy-button
                *ngIf="dataItem.status === orderStatus.Received && !isEditingReceivedItems"
                type="button"
                class="supy-orders-list__actions--info-btn"
                color="secondary"
                name="edit"
                (buttonClick)="onEditClick()"
              >
                <span>Edit</span>
              </supy-button>
              <supy-button
                *ngIf="dataItem.status === orderStatus.Received && isEditingReceivedItems"
                type="button"
                variant="outlined"
                color="secondary"
                name="save"
                (buttonClick)="onSaveClick(dataItem, false)"
              >
                <span>Save</span>
              </supy-button>
            </div>

            <div *ngIf="canConfirmRejectOrder" [class]="{ 'supy-orders-list__supplier-actions': !isAdmin }">
              <supy-button
                *ngIf="!isAdmin"
                type="button"
                class="supy-orders-list__actions--success-btn"
                color="secondary"
                name="download-pdf"
                (buttonClick)="onDownloadPdf(dataItem.id)"
              >
                <supy-icon name="download"></supy-icon>
                <span>Download</span>
              </supy-button>
              <div *ngIf="canShowSupplierAction(dataItem)" class="supy-orders-list__actions">
                <supy-button
                  type="button"
                  class="supy-orders-list__actions--danger-btn"
                  color="secondary"
                  name="reject-order"
                  (buttonClick)="onRejectOrder(dataItem.id)"
                >
                  <supy-icon name="close" size="extra-small"></supy-icon>
                  <span>Reject Order</span>
                </supy-button>

                <supy-button
                  type="button"
                  class="supy-orders-list__actions--primary-btn"
                  color="secondary"
                  name="confirm-order"
                  (buttonClick)="onConfirmOrder(dataItem)"
                >
                  <supy-icon name="check" size="extra-small"></supy-icon>
                  <span>Confirm Order</span>
                </supy-button>
              </div>
              <div *ngIf="dataItem.status === orderStatus.Rejected" class="supy-orders-list__rejected">
                <span>Reason:</span>
                <span class="supy-orders-list__rejected-reason">{{ getRejectedReason(dataItem) }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #emptyTemplate>
        <div class="supy-grid__empty-template" i18n="@@orders.list.noOrders">No orders found.</div>
      </ng-template>
    </supy-grid>
  </div>
</div>

<ng-template #lpoTemplate let-value="value" let-rowData="rowData">
  <div class="supy-orders-list__order-number-wrapper">
    <a
      class="supy-orders-list__order-number"
      [routerLink]="[rowData?.id, 'details']"
      [queryParamsHandling]="mergeParams ? 'merge' : ''"
    >
      {{ value }}
    </a>
    <supy-icon
      *ngIf="
        showPreferredFlag &&
        (rowData?.preferredType === preferredType.RevenueSharing ||
          rowData?.channelPreferredType === preferredType.RevenueSharing)
      "
      size="small"
      name="star"
      color="primary"
    ></supy-icon>
  </div>
</ng-template>

<ng-template #channelOrderIndexTemplate let-value="value">
  {{ value }}
</ng-template>

<ng-template #branchTemplate let-value="value">
  {{ getLocalizedName(value?.retailer?.outlet?.name) }} - {{ value?.retailer?.name }}
</ng-template>

<ng-template #supplierTemplate let-rowData="rowData" let-value="value">
  <a
    *ngIf="isAdmin; else supplierName"
    class="supy-orders-list__order-number"
    [routerLink]="['/suppliers', value?.supplier?.id, 'channels', rowData?.channelId, 'users']"
    target="_blank"
    >{{ value?.supplier?.name }}</a
  >
  <ng-template #supplierName>
    <span>{{ rowData?.channel?.displayName?.supplier ?? value?.supplier?.name }}</span>
  </ng-template>
</ng-template>

<ng-template #requestDateTemplate let-value="value">
  <span class="supy-orders-list__date">{{ value | date: 'd - M - yyyy' }}</span>
  <span class="supy-orders-list__time">{{ value | date: 'h:mm a' }}</span>
</ng-template>

<ng-template #deliveryDateTemplate let-value="value">
  <span class="supy-orders-list__date">{{ value | date: 'd - M - yyyy' }}</span>
</ng-template>

<ng-template #itemsCountTemplate let-rowData="rowData">{{ getItemsCount(rowData) }}</ng-template>

<ng-template #orderedTotalTemplate let-value="value" let-rowData="rowData">{{
  value || 0 + ' ' | currency: rowData?.country?.currency ?? getCurrency(rowData?.countryId)
}}</ng-template>

<ng-template #receivedTotalTemplate let-value="value" let-rowData="rowData">{{
  value || 0 + ' ' | currency: rowData?.country?.currency ?? getCurrency(rowData?.countryId)
}}</ng-template>

<ng-template #statusTemplate let-value="value">
  <supy-order-status-badge
    [status]="value"
    [text]="canConfirmRejectOrder && !isAdmin ? getOrderStatusForSupplier(value) : value"
  ></supy-order-status-badge>
</ng-template>
