<div class="retailer-linked-grns">
  <ng-container *ngIf="grns?.length; else emptyTemplate"></ng-container>
  <div *ngFor="let grn of grns; trackBy: trackBy" class="retailer-linked-grns__row">
    <h6 (click)="navigateToGrn(grn)">{{ grn.document?.number ?? 'GRN' }}</h6>
    <supy-icon-status-badge
      [status]="statusBadgeMapper[grn.status]"
      [text]="statusNameMapper[grn.status]"
      width="100%"
      weight="normal"
    ></supy-icon-status-badge>
  </div>
  <ng-template #emptyTemplate>
    <div class="retailer-linked-grns__empty-template" *ngIf="!grns?.length">
      <supy-icon name="exclamation-hex" size="medium"></supy-icon>
      <span i18n="@@orders.grns.noGrns">No GRNs</span>
    </div>
  </ng-template>
</div>
