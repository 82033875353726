<div class="retailer-order-details-widget" [class.retailer-order-details-widget--disabled]="disabled">
  <div class="retailer-order-details-widget--initial-loading" *ngIf="isInitialLoading"></div>
  <div *ngIf="header; else headerContent" class="retailer-order-details-widget__header">
    <supy-icon *ngIf="headerIcon" [name]="headerIcon" size="small"></supy-icon>
    <h5 class="retailer-order-details-widget__header-title">{{ header }}</h5>
  </div>
  <ng-template #headerContent>
    <div class="retailer-order-details-widget__header">
      <ng-content select="[widgetHeader]"></ng-content>
    </div>
  </ng-template>
  <p
    *ngIf="content; else widgetContent"
    class="retailer-order-details-widget__content"
    [ngClass]="{ 'retailer-order-details-widget__content-with-margin': headerIcon }"
    [title]="content"
  >
    {{ content }}
  </p>
  <ng-template #widgetContent>
    <ng-content></ng-content>
  </ng-template>
</div>
