<div class="supy-order-items-grid">
  <supy-grid-poc
    [data]="orderItems()"
    [defaultColDefs]="defaultColDefs()"
    [columnDefs]="columnDefs()"
    (gridReady)="setColumnDefs()"
    [getRowClass]="getRowClass"
  ></supy-grid-poc>

  <supy-order-items-footer
    [order]="orderValue()"
    [showComments]="showComments"
    [currencyPrecision]="currencyPrecision"
    [rejectionReason]="rejectionReason()"
    [orderSubTotal]="orderSubTotal()"
    [orderVatValue]="orderVatValue()"
    [orderVatPercentage]="orderVatPercentage()"
    [orderTotalAfterVat]="orderTotalAfterVat()"
    [currency]="currency()"
    [hidePrices]="hidePrices"
    [hideUnits]="hideUnits"
  ></supy-order-items-footer>
</div>
