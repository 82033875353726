import { getApiDetailsDecorator, IdType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { Packaging } from '@supy/packaging';

import { GrnBaseChannelItem, GrnChannelItem, GrnChannelItemRequest } from '../../entities';

const ApiProperty = getApiDetailsDecorator<GrnChannelItemRequest>();

export class GrnChannelItemPayload extends GrnBaseChannelItem {
  constructor(args: NonFunctionProperties<GrnChannelItem>) {
    super(args);

    this.retailerItem = args.retailerItem;
  }

  @ApiProperty() readonly retailerItem: IdType;

  serialize(): GrnChannelItemRequest {
    let packaging: Packaging | null;

    if (this.packaging) {
      const { createdBy, createdAt, updatedBy, updatedAt, ...props } = this.packaging;

      packaging = props;
    }

    return {
      id: this.id,
      itemCode: this.itemCode,
      name: this.name,
      uom: this.uom,
      retailerItem: this.retailerItem,
      packaging,
    };
  }
}
