import { Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { BaseUnleashResolver, UnleashService } from '@supy/common';

import { Order } from '../../core';
import { OrdersService } from '../../services';

@Injectable()
export class OrderResolver extends BaseUnleashResolver<Order> {
  constructor(
    private readonly ordersService: OrdersService,
    protected readonly unleashService: UnleashService,
  ) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Order> | Observable<Order> {
    return this.ordersService
      .getOrder(route.params.orderId as string)
      .pipe(tap(({ retailerId }) => this.updateContextAndResolve({ userId: retailerId })));
  }
}
