import { IdType } from '@supy/common';

import { PercentageValue } from '../order';
import { GrnChannelItemResponse } from './grn-channel-item.model';

export enum GrnItemType {
  Item = 'item',
  Credit = 'credit',
}

export interface GrnItemTaxRateResponse {
  readonly name: string;
  readonly taxCode: string;
  readonly rate: number;
  readonly amount: number;
}

export type GrnItemTaxRateRequest = GrnItemTaxRateResponse;

export interface GrnItemQuantities {
  readonly document: number;
  readonly received: number;
  readonly ordered?: number;
  readonly isCreditCreated?: boolean;
}

export interface GrnItemPrices {
  readonly item: number;
  readonly invoice: number;
  readonly isCreditCreated?: boolean;
  readonly isDiscounted?: boolean;
  readonly itemHandlingFee?: number;
  readonly updatePrice?: boolean;
}

export interface GrnItemPricesRequest extends GrnItemPrices {
  readonly updatePrice?: boolean;
}

export interface GrnBaseItemResponse {
  readonly quantities: GrnItemQuantities;
  readonly prices: GrnItemPrices;
  readonly channelItem: GrnChannelItemResponse;
  readonly appliedTax?: GrnItemTaxRateResponse;
  readonly comment?: string;
  readonly type: GrnItemType;
  readonly allocatedCreditNote: IdType;
}

export interface GrnItemResponse extends GrnBaseItemResponse {
  readonly id: string;
  readonly invoiceAdjustment?: PercentageValue;
  readonly partiallyReceived?: boolean;
}

export interface GrnCreditNoteItemResponse extends GrnBaseItemResponse {
  readonly id: string;
}

export interface GrnItemBaseRequest {
  readonly channelItem: IdType;
  readonly quantities: GrnItemQuantitiesRequest;
  readonly invoiceAdjustment?: PercentageValue;
  readonly appliedTax?: GrnItemTaxRateRequest;
  readonly partiallyReceived?: boolean;
  readonly prices: GrnItemPricesRequest;
  readonly comment?: string;
  readonly type?: GrnItemType;
  readonly allocatedCreditNote?: IdType;
}

export type GrnItemCreateRequest = GrnItemBaseRequest;

export interface GrnItemUpdateRequest extends GrnItemBaseRequest {
  readonly id: string;
}

export interface GrnItemQuantitiesRequest {
  readonly ordered?: number;
  readonly document: number;
  readonly received: number;
  readonly isCreditCreated?: boolean;
}
