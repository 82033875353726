import { FilterConfig, SelectType } from '@supy/components';

export interface OrdersMappedFilters {
  readonly search: string;
  readonly dateRange: { readonly start: Date; readonly end: Date };
  readonly branches: string[];
  readonly suppliers: string[];
}

export function getOrdersFiltersConfig(): FilterConfig {
  return {
    search: {
      placeholder: $localize`:@@orders.retailer.filter.search:Search by PO `,
      value: null,
    },
    dropdowns: [
      {
        value: null,
        selectType: SelectType.DateRange,
        placeholder: $localize`:@@common.date:Date`,
        name: 'dateRange',
        canSelectSingleDate: true,
        clearable: true,
      },
      {
        multiple: true,
        multipleSelectionStrategy: 'children',
        name: 'branches',
        options: [],
        placeholder: $localize`:@@common.branchLocations:Branch & Locations`,
        returnStrategy: 'key',
        selectType: SelectType.DropdownTree,
        value: [],
        width: '12rem',
      },
      {
        localSearch: true,
        multiple: true,
        name: 'suppliers',
        options: [],
        placeholder: $localize`:@@suppliers:Suppliers`,
        selectType: SelectType.DropdownTree,
        value: [],
        valueKey: 'id',
        returnStrategy: 'key',
        multipleSelectionStrategy: 'node',
        displayStrategy: 'value',
      },
    ],
  };
}
