import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ResourceEnum, SupplierReturnActionEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Lock extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.Lock,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class SupplierReturnViewList extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.ViewList,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class SupplierReturnView extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class CreateSupplierReturn extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateLocked extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.UpdateLocked,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class Post extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.Post,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateQuantities extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.UpdateQuantities,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateInvoicePrices extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.UpdateInvoicePrices,
      resource,
      principal,
    });
  }
}

export class SupplierReturnPermissionStrategy {
  static Lock = Lock;
  static ViewList = SupplierReturnViewList;
  static View = SupplierReturnView;
  static Create = CreateSupplierReturn;
  static UpdateLocked = UpdateLocked;
  static Post = Post;
  static UpdateQuantities = UpdateQuantities;
  static UpdateInvoicePrices = UpdateInvoicePrices;
}
