import { IdType, OutletData, SimpleBranch, SimpleDocument, Supplier } from '@supy/common';

import { GrnChannelItemResponse } from './grn-channel-item.model';
import { GrnItemTaxRateResponse } from './grn-item.model';

export enum CreditNoteStatus {
  Pending = 'pending',
  Allocated = 'allocated',
  Open = 'open',
  Closed = 'closed',
  Archived = 'archived',
  Draft = 'draft',
}

export enum GrnCreditNoteType {
  Quantity = 'quantity',
  Price = 'price',
}

export interface BaseCreditNoteResponse {
  readonly id: string;
  readonly item: GrnChannelItemResponse;
  readonly quantity: number;
  readonly price: number;
  readonly total: number;
  readonly comment?: string;
  readonly creditType: GrnCreditNoteType;
  readonly status: CreditNoteStatus;
  readonly appliedTax?: GrnItemTaxRateResponse;
  readonly createdAt?: string | Date;
}

export interface CreditNoteResponse extends BaseCreditNoteResponse {
  readonly supplier?: Supplier;
  readonly location: SimpleBranch;
  readonly allocatedGrn?: SimpleDocument;
  readonly issuerGrn: SimpleDocument;
  readonly outlet?: OutletData;
  readonly updatedAt?: string | Date;
}

export interface GrnCreditNoteResponse extends BaseCreditNoteResponse {
  readonly allocatedCreditNote?: IdType;
}

export interface CreditNoteQueryParams {
  readonly id: string;
  readonly status: CreditNoteStatus;
  readonly creditType: GrnCreditNoteType;
  readonly 'supplier.id': string;
  readonly 'retailer.id': string;
  readonly 'location.id': string;
  readonly 'channel.id': string;
  readonly 'item.id': string;
  readonly 'item.itemCode': string;
  readonly 'item.name.en': string;
  readonly 'issuerGrn.id': string;
  readonly 'allocatedGrn.id': string;
  readonly createdAt: Date;
}
