import { BehaviorSubject, Observable } from 'rxjs';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  IChangeCheckboxEventArgs,
  IDialogCancellableEventArgs,
  IDialogEventArgs,
} from '@infragistics/igniteui-angular';
import { fadeIn, swingInTopFwd } from '@infragistics/igniteui-angular/animations';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable, Uom } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';
import { CreateItemPackageRequest, CreatePackageRequest, ListingBaseItemOutput } from '@supy/inventory';
import { Packaging } from '@supy/packaging';
import { getLocalizedName } from '@supy/settings';

export type FormNumber = 1 | 2;

@Component({
  selector: 'supy-create-item-dialog',
  templateUrl: './create-item-dialog.component.html',
  styleUrls: ['./create-item-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('swingIn', [
      transition('void => *', [
        useAnimation(swingInTopFwd, {
          params: {
            duration: '.35s',
            easing: 'ease-out',
          },
        }),
      ]),
    ]),
    trigger('fadeIn', [
      transition('void => *', [
        useAnimation(fadeIn, {
          params: {
            duration: '.35s',
            easing: 'ease-out',
          },
        }),
      ]),
    ]),
  ],
})
export class CreateItemDialogComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  @ViewChild('priceInput') readonly priceInput: HTMLInputElement;

  @Input() set baseItems(value: ListingBaseItemOutput[]) {
    this.baseItemsChange.next(value);
  }

  @Input() set selectedItemPackages(value: Packaging[]) {
    this.selectedItemPackagesChange.next(value);
  }

  @Input() set baseUnit(value: Uom) {
    this.baseUnitChange.next(value);
    this.checkIfPieceAndSetupValue();
  }

  @Input() set packageExists(value: boolean) {
    this.packageExistsChange.next(value);
  }

  @Input() readonly isItemSearchLoading$: Observable<boolean>;
  @Input() readonly isItemLoading$: Observable<boolean>;
  @Input() readonly isCreateItemLoading$: Observable<boolean>;
  @Input() readonly isPackageCheckLoading$: Observable<boolean>;

  @Input() readonly closeText = 'Cancel';
  @Input() readonly currency$: Observable<Currency>;

  @Output() readonly searchBaseItems: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly itemSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly packageSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly submitItem: EventEmitter<CreateItemPackageRequest> = new EventEmitter<CreateItemPackageRequest>();

  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();

  protected readonly form = new FormGroup({
    retailerItem: new FormControl<string[] | null>(null),
    price: new FormControl<string | null>(null),
    selectBasePackage: new FormControl<string | null>(null),
    basePackage: new FormGroup({
      unitName: new FormControl<string | null>(null),
      baseUnit: new FormControl<string | null>({ value: null, disabled: true }),
      quantity: new FormControl<number | null>(null),
    }),
    packPackage: new FormGroup({
      unitName: new FormControl<string | null>(null),
      quantity: new FormControl<number | null>(null),
    }),
  });

  protected currentForm: FormNumber = 1;
  protected multiLevelPackage = false;

  private readonly baseItemsChange = new BehaviorSubject<ListingBaseItemOutput[]>([]);
  protected readonly baseItems$ = this.baseItemsChange.asObservable();

  private readonly selectedItemPackagesChange = new BehaviorSubject<Packaging[]>([]);
  protected readonly selectedItemPackages$ = this.selectedItemPackagesChange.asObservable();

  private readonly baseUnitChange = new BehaviorSubject<Uom>(null);
  protected readonly baseUnit$ = this.baseUnitChange.asObservable();

  private readonly packageExistsChange = new BehaviorSubject<boolean>(false);
  protected readonly packageExists$ = this.packageExistsChange.asObservable();

  protected readonly isItemLocked = signal(false);

  get title(): string {
    return this.isFirstForm ? 'Create new item' : `Create Package For ${getLocalizedName(this.selectedItem?.name)}`;
  }

  get isFirstForm(): boolean {
    return this.currentForm === 1;
  }

  get isLastForm(): boolean {
    return this.currentForm === 2;
  }

  get basePackageQuantityControl(): AbstractControl<number> {
    return this.form.get('basePackage.quantity') as FormControl<number | null>;
  }

  get packPackageQuantityControl(): AbstractControl<number> {
    return this.form.get('packPackage.quantity') as FormControl<number | null>;
  }

  get selectedItem(): ListingBaseItemOutput {
    const retailerItem = this.form.get('retailerItem')?.value;

    if (!retailerItem?.length) {
      return null;
    }

    return this.baseItemsChange.value?.find(item => item.id === retailerItem.toString());
  }

  get canCreateItem(): boolean {
    const { retailerItem, price, selectBasePackage } = this.form.value;

    return (
      !!(retailerItem?.length && price && selectBasePackage && !this.packageExistsChange.value) && this.isFirstForm
    );
  }

  get canCreatePackage(): boolean {
    const { basePackage, packPackage, price } = this.form.value;

    const mainCondition = basePackage?.quantity && price;

    if (this.multiLevelPackage) {
      return !!(mainCondition && packPackage?.quantity);
    }

    return !!mainCondition;
  }

  get isPiece(): boolean {
    return this.baseUnitChange.value?.isPiece;
  }

  get basePiecePackage(): Packaging {
    return this.selectedItemPackagesChange.value?.find(pack => pack.quantity.baseUnit === 1);
  }

  get basePackageName(): string {
    const { basePackage } = this.form.getRawValue();

    return `${basePackage?.quantity ?? ''}${this.baseUnitChange.value?.name ?? ''}${
      basePackage?.unitName ? ' ' + basePackage?.unitName : ''
    }`;
  }

  get packPackageName(): string {
    const { packPackage } = this.form.getRawValue();

    return `${packPackage.unitName ? `${packPackage.unitName} ` : ''}(${
      packPackage.quantity ? `${packPackage.quantity}x` : ''
    }${this.basePackageName})`;
  }

  get fullPackageName(): string {
    return `${getLocalizedName(this.selectedItem?.name)} ${
      this.multiLevelPackage ? this.packPackageName : this.basePackageName
    }`;
  }

  constructor() {
    super();
  }

  onRetailerItemChanged(item: string[]): void {
    this.form.patchValue({
      price: null,
      selectBasePackage: null,
    });

    this.packageExistsChange.next(false);

    if (!item?.length) {
      return;
    }

    if (this.selectedItem.isLocked) {
      this.isItemLocked.set(true);
    } else {
      this.isItemLocked.set(false);
      this.itemSelected.emit(item.toString());
    }
  }

  onCreateNewPackageClicked(): void {
    this.form.patchValue({
      price: null,
      selectBasePackage: null,
      basePackage: {
        baseUnit: this.baseUnitChange.value?.name,
        unitName: null,
      },
    });

    this.setVisibleForm(2);

    this.checkIfPieceAndSetupValue();
  }

  checkIfPieceAndSetupValue(): void {
    if (!this.isPiece) {
      return;
    }

    this.multiLevelPackage = true;

    this.form.patchValue({
      basePackage: {
        baseUnit: this.baseUnitChange.value?.name,
        unitName: this.basePiecePackage?.unitName ?? '',
        quantity: 1,
      },
    });

    this.form.get('basePackage.unitName').disable();
  }

  onGoBackClicked(): void {
    this.form.patchValue({
      price: null,
      basePackage: {
        unitName: null,
        quantity: null,
      },
      packPackage: {
        unitName: null,
        quantity: null,
      },
    });

    this.multiLevelPackage = false;
    this.packageExistsChange.next(false);

    this.form.get('basePackage.unitName').enable();

    this.setVisibleForm(1);
  }

  onPackageChange({ value }: IChangeCheckboxEventArgs): void {
    this.packageSelected.emit(value as string);
  }

  setVisibleForm(form: FormNumber): void {
    this.currentForm = form;
  }

  onAddPackageLevel(): void {
    this.multiLevelPackage = true;
  }

  onRemovePackageLevel(): void {
    this.multiLevelPackage = false;

    this.form.get('packPackage').reset();
  }

  focusPrice(): void {
    this.focusInput(this.priceInput);
  }

  focusInput(input: HTMLInputElement): void {
    setTimeout(() => input?.focus(), 100);
  }

  onSubmit(): void {
    this.isFirstForm ? this.createItem() : this.createPackage();
  }

  createItem(): void {
    const { retailerItem, price, selectBasePackage } = this.form.getRawValue();

    const request: CreateItemPackageRequest = {
      retailerItem: { id: retailerItem.toString() },
      price: +price,
      basePackage: {
        id: selectBasePackage,
      },
      supplier: null,
    };

    this.submitItem.emit(request);
  }

  createPackage(): void {
    const { retailerItem, basePackage, packPackage, price } = this.form.getRawValue();

    let packPackageRequest: CreatePackageRequest = {
      quantity: packPackage?.quantity,
      packageName: this.packPackageName,
    };

    let basePackageRequest: CreatePackageRequest = {
      quantity: basePackage?.quantity,
      packageName: this.basePackageName,
    };

    if (basePackage?.unitName) {
      basePackageRequest = {
        ...basePackageRequest,
        unitName: basePackage?.unitName,
      };
    }

    if (packPackage?.unitName) {
      packPackageRequest = {
        ...packPackageRequest,
        unitName: packPackage?.unitName,
      };
    }

    let request: CreateItemPackageRequest = {
      retailerItem: { id: retailerItem.toString() },
      price: +price,
      basePackage: basePackageRequest,

      supplier: null,
      isNewPackage: true,
    };

    if (this.multiLevelPackage) {
      request = {
        ...request,
        packPackage: packPackageRequest,
      };
    }

    this.submitItem.emit(request);
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
