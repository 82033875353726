import { UpdateChannelItemRequest } from '@supy/channel-items';

import { ModifyOrderRequest, OrderItem, UpdateOrderRequest } from '../../../core';

export enum OrderListColumnType {
  LPO = 'lpo',
  ChannelOrderIndex = 'channelOrderIndex',
  Branch = 'branch',
  Supplier = 'supplier',
  RequestDate = 'requestDate',
  DeliveryDate = 'deliveryDate',
  OrderedTotal = 'orderedTotal',
  ReceivedTotal = 'receivedTotal',
  Status = 'status',
  ItemsCount = 'itemsCount',
  Code = 'code',
}

export interface OrderListColumn {
  sortable: boolean;
  header: string;
  field: string;
  type: OrderListColumnType;
  width?: string;
}

export interface ISaveOrder {
  orderId: string;
  channelItems: UpdateChannelItemRequest[];
  updateOrderRequest: UpdateOrderRequest;
  pushAfterSave?: boolean;
  branchId: string;
}

export interface IModifyOrder {
  readonly orderId: string;
  readonly modifyOrderRequest: ModifyOrderRequest | null;
}

export interface PartialReceivedOrderItem extends OrderItem {
  readonly checked?: boolean;
  readonly sealed?: boolean;
}
