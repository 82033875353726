import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { AggregatedLinkedOrderMetadata, AggregatedLinkedOrderResponse } from './aggregated-orders.model';
import { OrderStatus, SimpleChannel } from './common-order.entity';

const ApiProperty = getApiDetailsDecorator<AggregatedLinkedOrderResponse>();

export class AggregatedLinkedOrder {
  private constructor(args: NonFunctionProperties<AggregatedLinkedOrder>) {
    this.id = args.id;
    this.number = args.number;
    this.status = args.status;
    this.channel = args.channel;
    this.metadata = args.metadata;
    this.submittedAt = args.submittedAt;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly number: string;
  @ApiProperty() readonly status: OrderStatus;
  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly metadata: AggregatedLinkedOrderMetadata;
  @ApiProperty() readonly submittedAt: Date;

  static deserialize(data: AggregatedLinkedOrderResponse): AggregatedLinkedOrder {
    return new AggregatedLinkedOrder({
      id: data.id,
      channel: data.channel,
      metadata: data.metadata,
      number: data.number,
      status: data.status,
      submittedAt: data.submittedAt && new Date(data.submittedAt),
    });
  }

  static deserializeList(data: AggregatedLinkedOrderResponse[]): AggregatedLinkedOrder[] {
    return data.map(order => this.deserialize(order));
  }
}
