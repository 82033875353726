import produce from 'immer';
import { catchError, EMPTY, map, switchMap, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  getShiftedDate,
  Query,
  QueryBuilder,
} from '@supy/common';
import { BaseActivity } from '@supy/components';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';

import {
  SupplierReturn,
  SupplierReturnPostManyResponse,
  SupplierReturnQueryProps,
  SupplierReturnStatus,
} from '../../core';
import { downloadSupplierReturnsList } from '../../helpers';
import { SupplierReturnsService } from '../../services';
import {
  SupplierReturnsCreate,
  SupplierReturnsDownloadPdf,
  SupplierReturnsExportList,
  SupplierReturnsGenerateDocumentNumber,
  SupplierReturnsGet,
  SupplierReturnsGetMany,
  SupplierReturnsInitFilters,
  SupplierReturnsLock,
  SupplierReturnsLockMany,
  SupplierReturnsNavigateToDetails,
  SupplierReturnsNavigateToList,
  SupplierReturnsPatchFilters,
  SupplierReturnsPatchRequestMetadata,
  SupplierReturnsPost,
  SupplierReturnsPostMany,
  SupplierReturnsResetFilters,
  SupplierReturnsResetPostManyResponse,
  SupplierReturnsSetFilters,
  SupplierReturnsUnlock,
  SupplierReturnsUpdate,
} from '../actions';

export interface SupplierReturnsStateModel extends EntityListStateModel<SupplierReturn> {
  readonly exportLoading: boolean;
  readonly filters: SupplierReturnsFilters;
  readonly generatedDocumentNumber?: string;
  readonly postManyResponse?: SupplierReturnPostManyResponse;
  requestMetadata: SupplierReturnsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

export interface SupplierReturnsFilters {
  readonly documentNumber: string;
  readonly end?: number | null;
  readonly locations: string[];
  readonly start?: number | null;
  readonly status: SupplierReturnStatus;
  readonly suppliers: string[];
}

export interface SupplierReturnsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
}

const FILTERS_DEFAULT: SupplierReturnsFilters = {
  status: null,
  locations: [],
  documentNumber: null,
  suppliers: [],
  start: null,
  end: null,
};

const REQUEST_META_DEFAULT = { page: 0, limit: 25, retailerId: null };
const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

const SUPPLIER_RETURNS_STATE_TOKEN = new StateToken<SupplierReturnsStateModel[]>('supplierReturns');

@State<SupplierReturnsStateModel>({
  name: SUPPLIER_RETURNS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    exportLoading: false,
  },
})
@Injectable()
export class SupplierReturnsState extends EntityListState {
  readonly #store = inject(Store);
  readonly #supplierReturnsService = inject(SupplierReturnsService);
  readonly #utcOffset = toSignal(this.#store.select(SettingsState.utcOffset));
  readonly #ianaTimezone = toSignal(this.#store.select(SettingsState.ianaTimeZone));
  readonly #currency = toSignal(this.#store.select(SettingsState.currency));

  constructor(protected readonly router: Router) {
    super(router);
  }

  @Selector()
  static supplierReturns(state: SupplierReturnsStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static postManyResponse(state: SupplierReturnsStateModel) {
    return state.postManyResponse;
  }

  @Selector()
  static currentSupplierReturn(state: SupplierReturnsStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static filters(state: SupplierReturnsStateModel) {
    return EntityListState.currentFilters<SupplierReturnsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: SupplierReturnsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: SupplierReturnsStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static isFirst(state: SupplierReturnsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: SupplierReturnsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static exportLoading(state: SupplierReturnsStateModel) {
    return state.exportLoading;
  }

  @Selector()
  static activities(state: SupplierReturnsStateModel) {
    const supplierReturn = SupplierReturnsState.currentSupplierReturn(state);

    return supplierReturn?.activities?.reduce<BaseActivity[]>(
      (acc, cur) => [
        {
          action: cur.type,
          user: cur.user,
          createdAt: cur.createdAt,
        },
        ...acc,
      ],
      [],
    );
  }

  @Selector()
  static generatedDocumentNumber(state: SupplierReturnsStateModel) {
    return state.generatedDocumentNumber;
  }

  static supplierReturn(id: string, next?: boolean) {
    return createSelector([SupplierReturnsState], (state: SupplierReturnsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Action(SupplierReturnsInitFilters)
  initFilters(ctx: StateContext<SupplierReturnsStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(SupplierReturnsResetPostManyResponse)
  clearPostManyResponse(ctx: StateContext<SupplierReturnsStateModel>) {
    return ctx.patchState({ postManyResponse: null });
  }

  @Action(SupplierReturnsSetFilters)
  setFilters(ctx: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsSetFilters) {
    super.setFilter(ctx, payload, FILTERS_DEFAULT);

    ctx.dispatch([new SupplierReturnsPatchRequestMetadata({ page: 0 }), new SupplierReturnsGetMany()]);
  }

  @Action(SupplierReturnsPatchFilters)
  patchFilters(ctx: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsPatchFilters) {
    super.patchFilter(ctx, payload, FILTERS_DEFAULT);

    ctx.dispatch([new SupplierReturnsPatchRequestMetadata({ page: 0 }), new SupplierReturnsGetMany()]);
  }

  @Action(SupplierReturnsResetFilters)
  resetFilters(ctx: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsResetFilters) {
    super.resetFilter(ctx, {
      ...FILTERS_DEFAULT,
      ...payload,
    });

    ctx.dispatch([new SupplierReturnsPatchRequestMetadata({ page: 0 }), new SupplierReturnsGetMany()]);
  }

  @Action(SupplierReturnsPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...draft.requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(SupplierReturnsGet)
  get(ctx: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsGet) {
    return super.getOne(ctx, {
      ...payload,
      fetchApi: () =>
        this.#supplierReturnsService.get(payload.id).pipe(
          map(supplierReturn =>
            SupplierReturn.deserialize(supplierReturn, {
              ianaTimezone: this.#ianaTimezone(),
            }),
          ),
        ),
    });
  }

  @Action(SupplierReturnsGetMany)
  getMany(ctx: StateContext<SupplierReturnsStateModel>) {
    return this.#supplierReturnsService.getMany(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(
          ctx,
          data.map(supplierReturn =>
            SupplierReturn.deserialize(supplierReturn, {
              ianaTimezone: this.#ianaTimezone(),
            }),
          ),
        );
      }),
    );
  }

  @Action(SupplierReturnsCreate)
  create(ctx: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsCreate) {
    return this.#supplierReturnsService
      .create(payload.body)
      .pipe(switchMap(({ id }) => ctx.dispatch(new SupplierReturnsGet({ id }))));
  }

  @Action(SupplierReturnsUpdate)
  update(_: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsUpdate) {
    return this.#supplierReturnsService.update(payload.id, payload.body);
  }

  @Action(SupplierReturnsPost)
  post(_: StateContext<SupplierReturnsStateModel>, { id }: SupplierReturnsPost) {
    return this.#supplierReturnsService.post(id);
  }

  @Action(SupplierReturnsPostMany)
  postMany(ctx: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsPostMany) {
    return this.#supplierReturnsService
      .postMany(payload)
      .pipe(tap(response => ctx.patchState({ postManyResponse: response })));
  }

  @Action(SupplierReturnsLock)
  lock(_: StateContext<SupplierReturnsStateModel>, { id }: SupplierReturnsLock) {
    return this.#supplierReturnsService.lock(id);
  }

  @Action(SupplierReturnsLockMany)
  lockMany(ctx: StateContext<SupplierReturnsStateModel>, { payload }: SupplierReturnsLockMany) {
    return this.#supplierReturnsService
      .lockMany(payload)
      .pipe(switchMap(() => ctx.dispatch(new SupplierReturnsGetMany())));
  }

  @Action(SupplierReturnsUnlock)
  unlock(_: StateContext<SupplierReturnsStateModel>, { id }: SupplierReturnsUnlock) {
    return this.#supplierReturnsService.unlock(id);
  }

  @Action(SupplierReturnsGenerateDocumentNumber)
  generateDocumentNumber(ctx: StateContext<SupplierReturnsStateModel>) {
    return this.#store.selectOnce(CurrentRetailerState.get).pipe(
      switchMap(retailerId => this.#supplierReturnsService.generateDocumentNumber({ retailer: { id: retailerId } })),
      tap(({ number }) => ctx.patchState({ generatedDocumentNumber: number })),
    );
  }

  @Action(SupplierReturnsDownloadPdf)
  downloadPdf(_: StateContext<SupplierReturnsStateModel>, { id }: SupplierReturnsDownloadPdf) {
    return this.#supplierReturnsService.downloadPdf(id).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl, '_blank');
      }),
    );
  }

  @Action(SupplierReturnsExportList, { cancelUncompleted: true })
  exportList(ctx: StateContext<SupplierReturnsStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.#supplierReturnsService.getMany(query).pipe(
      tap(({ data }) => {
        ctx.patchState({
          exportLoading: false,
        });

        void downloadSupplierReturnsList(
          data.map(supplierReturn =>
            SupplierReturn.deserialize(supplierReturn, {
              ianaTimezone: this.#ianaTimezone(),
            }),
          ),
          this.#currency(),
        );
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  @Action(SupplierReturnsNavigateToList)
  navigateToList(ctx: StateContext<SupplierReturnsStateModel>) {
    return ctx.dispatch(new Navigate(['/accounting', 'supplier-returns']));
  }

  @Action(SupplierReturnsNavigateToDetails)
  navigateToDetails(ctx: StateContext<SupplierReturnsStateModel>, { id }: SupplierReturnsNavigateToDetails) {
    return ctx.dispatch(new Navigate(['/accounting', 'supplier-returns', id, 'details']));
  }

  private getQuery(
    state: SupplierReturnsStateModel,
    extras: {
      readonly paginationLimit?: number;
      readonly offset?: number;
    } = {},
  ): Query<SupplierReturnQueryProps> {
    const { locations, start, end, status, suppliers, documentNumber } = state.filters;

    const qb = new QueryBuilder<SupplierReturnQueryProps>({
      filtering: [],
      paging: {
        limit: extras.paginationLimit ? extras.paginationLimit : state.requestMetadata.limit,
        offset: extras.offset ? extras.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'document.documentDate',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (locations?.length) {
      qb.filtering.setFilter({
        by: 'location.id',
        match: locations,
        op: 'in',
      });
    }

    if (suppliers?.length) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: suppliers,
        op: 'in',
      });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        match: status,
        op: 'eq',
      });
    }

    if (documentNumber) {
      qb.filtering.setFilter({
        by: 'document.number',
        match: documentNumber,
        op: 'like',
      });
    }

    if (start && end && !documentNumber) {
      const utcOffset = this.#utcOffset();

      qb.filtering.withFiltering([
        {
          by: 'document.documentDate',
          op: 'lte',
          match: getShiftedDate(new Date(end), utcOffset).getTime(),
        },
        {
          by: 'document.documentDate',
          op: 'gte',
          match: getShiftedDate(new Date(start), utcOffset).getTime(),
        },
      ]);
    }

    return qb.build();
  }
}
