import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { trackByIndex } from '@supy/common';

import { GrnStatus, GrnStatusBadgeMapper, GrnStatusNameMapper, SimpleGrn } from '../../../core';

@Component({
  selector: 'supy-retailer-linked-grns',
  templateUrl: './retailer-linked-grns.component.html',
  styleUrls: ['./retailer-linked-grns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleCasePipe],
})
export class RetailerLinkedGrnsComponent {
  readonly #titleCasePipe = inject(TitleCasePipe);
  @Input() protected readonly grns: SimpleGrn[];

  protected readonly statusBadgeMapper = GrnStatusBadgeMapper;
  protected readonly statusNameMapper = GrnStatusNameMapper;
  protected readonly trackBy = trackByIndex;

  protected getComputedText(grn: SimpleGrn): string {
    switch (grn.status) {
      case GrnStatus.Draft:
        return $localize`:@@statusDrafted:Drafted`;
      case GrnStatus.Posted:
        return $localize`:@@posted:Posted`;
      case GrnStatus.Saved:
        return $localize`:@@saved:Saved`;

      default:
        return this.#titleCasePipe.transform(grn.status);
    }
  }

  protected navigateToGrn(grn: SimpleGrn): void {
    window.open(`/accounting/grns/${grn.id}/details`, '_blank');
  }
}
