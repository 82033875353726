import { Currency } from '@supy.api/dictionaries';

import { ChannelItem } from '@supy/channel-items';
import { PreferredType, Retailer, SimpleUser, SupplierSnapshot, User } from '@supy/common';
import { BaseActivity } from '@supy/components';
import { NonFunctionProperties } from '@supy/core';
import { Country } from '@supy/countries';
import { getLocalizedName } from '@supy/settings';

import {
  ProcurementOrderItemResponse,
  ProcurementOrderRequestType,
  ProcurementOrderResponse,
  ProcurementOrderStatus,
  ProcurementOrderUpdateResponse,
} from './procurement-order.model';

type ComputedProperties =
  | 'currency'
  | 'isDrafted'
  | 'isItemsAddable'
  | 'isQuantityUpdatable'
  | 'isReceivable'
  | 'isRepeatable'
  | 'isSubmittable'
  | 'isUpdatable'
  | 'submittedBy';

export class ProcurementOrder {
  constructor(args: Omit<NonFunctionProperties<ProcurementOrder>, ComputedProperties>) {
    this.activities = args.activities;
    this.branchName = args.branchName;
    this.channelId = args.channelId;
    this.channelPreferredType = args.channelPreferredType;
    this.comments = args.comments;
    this.country = args.country;
    this.createdAt = args.createdAt;
    this.deliveryDate = args.deliveryDate;
    this.id = args.id;
    this.locationName = args.locationName;
    this.number = args.number;
    this.orderedItems = args.orderedItems;
    this.orderedItemsTotal = args.orderedItemsTotal;
    this.receivedItems = args.receivedItems;
    this.receivedItemsTotal = args.receivedItemsTotal;
    this.retailer = args.retailer;
    this.retailerId = args.retailerId;
    this.status = args.status;
    this.supplier = args.supplier;
    this.vat = args.vat;

    this.currency = this.orderedItems[0]?.currency;
    this.isUpdatable = this.status === ProcurementOrderStatus.Received;
    this.isQuantityUpdatable =
      this.status === ProcurementOrderStatus.Received ||
      this.status === ProcurementOrderStatus.Submitted ||
      this.status === ProcurementOrderStatus.PartiallyReceived;

    this.isDrafted = this.status === ProcurementOrderStatus.Drafted;

    this.isReceivable =
      this.status === ProcurementOrderStatus.PartiallyReceived ||
      this.status === ProcurementOrderStatus.Submitted ||
      this.status === ProcurementOrderStatus.Confirmed;

    this.isRepeatable =
      this.status === ProcurementOrderStatus.Received || this.status === ProcurementOrderStatus.PartiallyReceived;

    this.isItemsAddable =
      this.status === ProcurementOrderStatus.Submitted || this.status === ProcurementOrderStatus.Confirmed;

    this.submittedBy = this.activities.find(
      ({ action }) => action === ProcurementOrderRequestTypeMapper[ProcurementOrderRequestType.Submitted],
    )?.user;
  }

  readonly activities: ProcurementOrderUpdate[];
  readonly branchName: string | null;
  readonly channelId: string;
  readonly channelPreferredType: PreferredType;
  readonly comments: string[];
  readonly country: Country;
  readonly createdAt: Date;
  readonly deliveryDate: string;
  readonly id: string;
  readonly locationName: string;
  readonly number: string;
  readonly orderedItems: ProcurementOrderItem[];
  readonly orderedItemsTotal: number;
  readonly receivedItems: ProcurementOrderItem[];
  readonly receivedItemsTotal: number;
  readonly retailer: Retailer;
  readonly retailerId: string;
  readonly status: ProcurementOrderStatus;
  readonly supplier: SupplierSnapshot;
  readonly vat: number;

  // Computed Properties
  readonly currency: Currency;
  readonly isDrafted: boolean;
  readonly isItemsAddable: boolean;
  readonly isReceivable: boolean;
  readonly isRepeatable: boolean;
  readonly isSubmittable: boolean;
  readonly isUpdatable: boolean;
  readonly isQuantityUpdatable: boolean;
  readonly submittedBy: SimpleUser | null;

  static deserialize(order: ProcurementOrderResponse): ProcurementOrder {
    const activities = ProcurementOrderUpdate.deserializeList(order.updates);
    const orderedItems = ProcurementOrderItem.deserializeList(order.ordered, order);
    const receivedItems = ProcurementOrderItem.deserializeList(order.received, order);
    const updates = ProcurementOrderUpdate.deserializeList(order.updates);
    const comments: string[] = [];

    if (order.comment?.length) {
      comments.push(order.comment);
    }

    for (const update of updates) {
      if (update.type === ProcurementOrderRequestType.Commented && update.comment) {
        comments.push(update.comment);
      }
    }

    return new ProcurementOrder({
      ...order,
      activities,
      branchName: order.partyInfo.retailer.outlet && getLocalizedName(order.partyInfo.retailer.outlet.name),
      comments,
      createdAt: order.createdAt && new Date(order.createdAt),
      locationName: order.partyInfo.retailer.name,
      orderedItems,
      orderedItemsTotal: order.orderedTotal,
      receivedItems,
      receivedItemsTotal: order.receivedTotal,
      supplier: order.partyInfo.supplier,
      retailer: order.partyInfo.retailer,
    });
  }

  static deserializeList(data: ProcurementOrderResponse[]): ProcurementOrder[] {
    return data.map(order => this.deserialize(order));
  }
}

export class ProcurementOrderItem {
  constructor(args: NonFunctionProperties<ProcurementOrderItem>) {
    this.currency = args.currency;
    this.id = args.id;
    this.name = args.name ?? args.nameAr;
    this.orderedQuantity = args.orderedQuantity;
    this.preferredType = args.preferredType;
    this.price = args.price;
    this.orderedTotal = args.orderedTotal;
    this.receivedTotal = args.receivedTotal;
    this.receivedQuantity = args.receivedQuantity;
    this.unit = args.unit;
  }

  readonly currency: Currency;
  readonly id: string;
  readonly name: string;
  readonly nameAr: string | null;
  readonly orderedQuantity: number;
  readonly orderedTotal: number;
  readonly preferredType: PreferredType;
  readonly price: number;
  readonly receivedQuantity: number;
  readonly receivedTotal: number;
  readonly unit: string;

  static deserialize(orderItem: ProcurementOrderItemResponse, order: ProcurementOrderResponse): ProcurementOrderItem {
    const receivedOrderItem = order.received.find(({ productId }) => orderItem.productId === productId);
    const orderedTotal = orderItem.quantity * orderItem.price;
    const receivedQuantity = receivedOrderItem?.quantity ?? orderItem.quantity;
    const receivedTotal = receivedOrderItem ? receivedOrderItem.quantity * orderItem.price : orderedTotal;

    return new ProcurementOrderItem({
      ...orderItem,
      id: orderItem.productId,
      nameAr: orderItem.name_ar,
      orderedQuantity: orderItem.quantity,
      orderedTotal,
      price: orderItem.price,
      receivedQuantity,
      receivedTotal,
    });
  }

  static deserializeList(
    data: ProcurementOrderItemResponse[],
    order: ProcurementOrderResponse,
  ): ProcurementOrderItem[] {
    return data.map(orderItem => this.deserialize(orderItem, order));
  }

  static deserializeChannelOrderItem(data: ChannelItem): ProcurementOrderItem {
    return new ProcurementOrderItem({
      id: data.id,
      currency: data.currency,
      name: getLocalizedName(data.name),
      nameAr: data.name.ar,
      orderedQuantity: 0,
      orderedTotal: 0,
      preferredType: data.preferredType,
      price: data.price,
      receivedQuantity: 0,
      receivedTotal: 0,
      unit: data.unit,
    });
  }

  static deserializeChannelOrderItemsList(data: ChannelItem[]) {
    return data.map(channelItem => this.deserializeChannelOrderItem(channelItem));
  }
}

export class ProcurementOrderUpdate implements BaseActivity {
  constructor(args: NonFunctionProperties<ProcurementOrderUpdate>) {
    this.action = args.action;
    this.comment = args.comment;
    this.createdAt = args.createdAt;
    this.type = args.type;
    this.user = args.user;
  }

  readonly action: string;
  readonly comment: string | null;
  readonly createdAt: Date;
  readonly type: ProcurementOrderRequestType;
  readonly user: User;

  static serialize(data: ProcurementOrderUpdateResponse): ProcurementOrderUpdate {
    return new ProcurementOrderUpdate({
      ...data,
      type: data.status,
      action: ProcurementOrderRequestTypeMapper[data.status],
      createdAt: data.createdAt && new Date(data.createdAt),
    });
  }

  static deserializeList(data: ProcurementOrderUpdateResponse[]): ProcurementOrderUpdate[] {
    return data.map(orderUpdate => this.serialize(orderUpdate));
  }
}

export const ProcurementOrderRequestTypeMapper: Record<ProcurementOrderRequestType, string> = {
  [ProcurementOrderRequestType.Commented]: 'Commented',
  [ProcurementOrderRequestType.Discarded]: 'Discarded',
  [ProcurementOrderRequestType.PartiallyReceived]: 'Partially Received',
  [ProcurementOrderRequestType.Received]: 'Received',
  [ProcurementOrderRequestType.Submitted]: 'Submitted',
  [ProcurementOrderRequestType.Modified]: 'Modified',
  [ProcurementOrderRequestType.Confirmed]: 'Confirmed',
};

export const ProcurementOrderStatusTextMapper: Record<ProcurementOrderStatus, string> = {
  [ProcurementOrderStatus.Confirmed]: 'Confirmed',
  [ProcurementOrderStatus.Rejected]: 'Rejected',
  [ProcurementOrderStatus.Drafted]: 'Drafted',
  [ProcurementOrderStatus.PartiallyReceived]: 'Partially Received',
  [ProcurementOrderStatus.Received]: 'Received',
  [ProcurementOrderStatus.Submitted]: 'Submitted',
};

export interface OrderFilters {
  readonly branches: string[];
  readonly endDate: Date | number;
  readonly poNumber: string;
  readonly startDate: Date | number;
  readonly status: ProcurementOrderStatus;
  readonly suppliers: string[];
  readonly retailerId: string;
}
