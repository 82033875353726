import { IdType } from '@supy/common';

import { Grn, GrnCreditNoteItem } from '../../core';
import { CreditNotesFilters, CreditNotesRequestMetadata } from '../state';

export class CreditNoteGetMany {
  static readonly type = '[CreditNote] GetMany';

  constructor(readonly ids?: string[]) {}
}

export interface CreditNoteGetManyBrGrnPayload extends Pick<Grn, 'id' | 'channel' | 'supplier' | 'location'> {
  readonly retailerId: string;
  readonly creditNoteItems: GrnCreditNoteItem[];
}
export class CreditNoteGetManyByGrn {
  static readonly type = '[CreditNote] GetManyByGrn';

  constructor(readonly payload: CreditNoteGetManyBrGrnPayload) {}
}

export class CreditNoteGet {
  static readonly type = '[CreditNote] Get';

  constructor(readonly payload?: { id: string; fromCache?: boolean }) {}
}

export class CreditNoteListExport {
  static readonly type = '[CreditNote] ListExport';
}

export class CreditNoteArchiveMany {
  static readonly type = '[CreditNote] ArchiveMany';

  constructor(readonly payload: { creditNotes: IdType[] }) {}
}

export class CreditNoteInitFilters {
  static readonly type = '[CreditNote] InitFilters';
}

export class CreditNoteSetFilter {
  static readonly type = '[CreditNote] SetFilter';
  constructor(readonly payload?: CreditNotesFilters) {}
}

export class CreditNotePatchFilter {
  static readonly type = '[CreditNote] PatchFilter';
  constructor(readonly payload?: Partial<CreditNotesFilters>) {}
}

export class CreditNoteResetFilter {
  static readonly type = '[CreditNote] ResetFilter';
}

export class CreditNotePatchRequestMeta {
  static readonly type = '[CreditNote] PatchRequestMeta';
  constructor(readonly payload: Partial<CreditNotesRequestMetadata>) {}
}
