import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseOrder } from '../../../core';

@Component({
  selector: 'supy-retailer-order-details-drawer-header[order]',
  templateUrl: './retailer-order-details-drawer-header.component.html',
  styleUrls: ['./retailer-order-details-drawer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrderDetailsDrawerHeaderComponent<T extends BaseOrder> {
  @Input() protected readonly isInventoryOrder: boolean;
  @Input() protected readonly isCkOrder: boolean;
  @Input() protected readonly isInitialLoading: boolean;
  @Input() protected readonly nextDisabled: boolean;
  @Input() protected readonly order: T;
  @Input() protected readonly previousDisabled: boolean;

  @Output() readonly closeClicked = new EventEmitter<void>();
  @Output() readonly nextClicked = new EventEmitter<T>();
  @Output() readonly previousClicked = new EventEmitter<T>();
  @Output() readonly expandClicked = new EventEmitter<T>();

  @Output() readonly downloadPdfClicked = new EventEmitter<T>();
}
