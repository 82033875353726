import { IANATimezone } from '@supy.api/dictionaries';

import {
  getApiDetailsDecorator,
  getDateInTimeZone,
  getEnumMember,
  IdType,
  isExistent,
  LocalizedSkeletonObjectType,
  SimpleUser,
  SkeletonObjectType,
  SupplierSnapshot,
} from '@supy/common';
import { BadgeStatus } from '@supy/components';
import { NonFunctionProperties } from '@supy/core';

import { SimpleChannel } from '../order';
import {
  SupplierReturnActivity,
  SupplierReturnActivityType,
  SupplierReturnMetadata,
  SupplierReturnResponse,
  SupplierReturnStatus,
  SupplierReturnTotals,
} from './supplier-return.model';
import { SupplierReturnDocument } from './supplier-return-document.entity';
import { SupplierReturnItem } from './supplier-return-item.entity';

const ApiProperty = getApiDetailsDecorator<SupplierReturnResponse>();

type SupplierReturnArgs = Omit<
  NonFunctionProperties<SupplierReturn>,
  | 'closedOn'
  | 'isDiscarded'
  | 'isDownloadable'
  | 'isLockable'
  | 'isLocked'
  | 'isPosted'
  | 'isSaved'
  | 'isStockUpdatable'
  | 'isUnlockable'
>;

export class SupplierReturn {
  private constructor(args: SupplierReturnArgs) {
    this.id = args.id;
    this.supplier = args.supplier;
    this.retailer = args.retailer;
    this.location = args.location;
    this.outlet = args.outlet;
    this.channel = args.channel;
    this.status = args.status;
    this.document = args.document;
    this.activities = args.activities;
    this.items = args.items;
    this.totals = args.totals;
    this.createdBy = args.createdBy;
    this.createdAt = args.createdAt;
    this.metadata = args.metadata;
    this.comment = args.comment;

    // Computed properties
    this.closedOn = this.activities.find(({ type }) => type === SupplierReturnActivityType.Posted)?.createdAt ?? null;
    this.isPosted = this.status === SupplierReturnStatus.Posted;
    this.isSaved = this.status === SupplierReturnStatus.Saved;
    this.isDownloadable = isExistent(this.id);
    this.isLocked = this.metadata?.isLocked && this.isSaved;
    this.isLockable = this.isSaved && !this.isLocked;
    this.isUnlockable = this.isSaved && this.isLocked;
    this.isDiscarded = this.status === SupplierReturnStatus.Discarded;
    this.isStockUpdatable = !this.isPosted;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly supplier: SupplierSnapshot;
  @ApiProperty() readonly retailer: IdType;
  @ApiProperty() readonly location: SkeletonObjectType;
  @ApiProperty() readonly outlet: LocalizedSkeletonObjectType;
  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly status: SupplierReturnStatus;
  @ApiProperty() readonly document: SupplierReturnDocument;
  @ApiProperty() readonly activities?: SupplierReturnActivity[];
  @ApiProperty() readonly items: SupplierReturnItem[];
  @ApiProperty() readonly totals: SupplierReturnTotals;
  @ApiProperty() readonly createdBy: SimpleUser;
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly metadata: SupplierReturnMetadata;
  @ApiProperty() readonly comment: string;

  // Computed properties
  readonly closedOn: Date | null;
  readonly isDownloadable: boolean;
  readonly isLocked: boolean;
  readonly isPosted: boolean;
  readonly isSaved: boolean;
  readonly isLockable: boolean;
  readonly isUnlockable: boolean;
  readonly isDiscarded: boolean;
  readonly isStockUpdatable: boolean;

  static deserialize(
    data: SupplierReturnResponse,
    {
      ianaTimezone,
    }: {
      readonly ianaTimezone: IANATimezone;
    },
  ): SupplierReturn {
    return new SupplierReturn({
      id: data.id,
      supplier: data.supplier,
      retailer: data.retailer,
      location: data.location,
      outlet: data.outlet,
      channel: data.channel,
      status: getEnumMember(SupplierReturnStatus, data.status),
      document: SupplierReturnDocument.deserialize(data.document, { ianaTimezone }),
      activities: data.activities ?? [],
      items: data.items?.map(SupplierReturnItem.deserialize),
      totals: data.totals,
      createdBy: data.createdBy,
      createdAt: data.createdAt,
      metadata: data.metadata,
      comment: data.comment,
    });
  }

  static deserializeNew({
    channel,
    location,
    outlet,
    supplier,
    number,
    ianaTimezone,
  }: DeserializedNewSupplierReturnArgs): SupplierReturn {
    return new SupplierReturn({
      activities: [],
      channel,
      comment: null,
      createdAt: new Date(),
      createdBy: null,
      document: {
        number,
        returnDate: getDateInTimeZone(new Date(), ianaTimezone),
        attachments: [],
        localFiles: [],
      },
      id: null,
      items: [],
      location,
      metadata: null,
      outlet,
      retailer: null,
      status: SupplierReturnStatus.New,
      supplier,
      totals: null,
    });
  }

  static deserializeExisting({ channel, ...props }: SupplierReturn): SupplierReturn {
    return new SupplierReturn({
      ...props,
      channel,
    });
  }
}

export const SupplierReturnStatusNameMapper: Record<SupplierReturnStatus, string> = {
  [SupplierReturnStatus.Discarded]: $localize`:@@discarded:Discarded`,
  [SupplierReturnStatus.New]: $localize`:@@new:New`,
  [SupplierReturnStatus.Pending]: $localize`:@@pending:Pending`,
  [SupplierReturnStatus.Posted]: $localize`:@@posted:Posted`,
  [SupplierReturnStatus.Saved]: $localize`:@@saved:Saved`,
};

export const SupplierReturnStatusBadgeMapper: Record<SupplierReturnStatus, BadgeStatus> = {
  [SupplierReturnStatus.Discarded]: 'light-error',
  [SupplierReturnStatus.New]: 'primary',
  [SupplierReturnStatus.Pending]: 'primary',
  [SupplierReturnStatus.Posted]: 'success',
  [SupplierReturnStatus.Saved]: 'info',
};

export type DeserializedNewSupplierReturnArgs = Pick<SupplierReturn, 'channel' | 'supplier' | 'outlet' | 'location'> &
  Pick<SupplierReturnDocument, 'number'> & {
    readonly ianaTimezone: IANATimezone;
  };
