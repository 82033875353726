import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CreditNoteActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
export class Create extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CreditNote,
      action: CreditNoteActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ViewList extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CreditNote,
      action: CreditNoteActionEnum.ViewList,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class Allocate extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CreditNote,
      action: CreditNoteActionEnum.Allocate,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class Archive extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.CreditNote,
      action: CreditNoteActionEnum.Archive,
      resource,
      principal,
    });
  }
}

export class CreditNotesPermissionStrategy {
  static Create = Create;
  static ViewList = ViewList;
  static Allocate = Allocate;
  static Archive = Archive;
}
