import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ResourceEnum, SupplierReturnActionEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Approve extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.SupplierReturn,
      action: SupplierReturnActionEnum.Approve,
      resource,
      principal,
    });
  }
}

export class CkCustomerReturnPermissionStrategy {
  static Approve = Approve;
}
