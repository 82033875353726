import produce from 'immer';
import { catchError, EMPTY, map, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import { EntityListState, EntityListStateModel, getShiftedDate, Query, QueryBuilder, removeEmpty } from '@supy/common';
import { BaseActivity, StatisticsCard } from '@supy/components';
import { AccountingIntegrationService } from '@supy/integrations';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';

import {
  Grn,
  GrnPostManyResponse,
  GrnQueryProps,
  GrnStatisticsCard,
  GrnStatisticsCardType,
  GrnStatisticsRequest,
  GrnStatus,
  GrnUpdateType,
  SimpleGrn,
} from '../../core';
import { downloadGrnList } from '../../helpers';
import { GrnService } from '../../services';
import {
  GrnsClearPostMany,
  GrnsCreate,
  GrnsDiscard,
  GrnsDownloadDetailsPdf,
  GrnsGet,
  GrnsGetManySimple,
  GrnsGetStatistics,
  GrnsInitFilters,
  GrnsLinkOrder,
  GrnsListExport,
  GrnsListPdfExport,
  GrnsLock,
  GrnsLockMany,
  GrnsPatchFilters,
  GrnsPatchRequestMeta,
  GrnsPost,
  GrnsPostFailed,
  GrnsPostMany,
  GrnsResetFilters,
  GrnsResetSequence,
  GrnsSetFilters,
  GrnsSync,
  GrnsUnlock,
  GrnsUpdate,
} from '../actions';

const GRNS_STATE_TOKEN = new StateToken<GrnsStateModel[]>('grns');

export interface GrnsStateModel extends EntityListStateModel<Grn> {
  filters: GrnsFilters;
  requestMetadata: GrnsRequestMetadata;
  statistics: StatisticsCard<GrnStatisticsCard>[];
  GrnPostManyResponse?: GrnPostManyResponse;
  responseMetadata: GrnsResponseMetadata;
  exportLoading: boolean;
}

export interface GrnsFilters {
  readonly status?: GrnStatus | null;
  readonly branches?: string[];
  readonly start?: number | null;
  readonly end?: number | null;
  readonly suppliers?: string[];
  readonly documentNumber?: string;
  readonly selectedStatistics?: string | null;
  readonly locked?: boolean | null;
  readonly channelId?: string | null;
}

export interface GrnSorting {
  readonly value: string;
  readonly field: string;
}

export interface GrnsRequestMetadata {
  readonly page: number;
  readonly limit: number;
  readonly retailerId: string | null;
}

export interface GrnsResponseMetadata {
  readonly total: number;
  readonly count: number;
}

const FILTERS_DEFAULT = {
  status: null,
  branches: [],
  start: undefined,
  end: undefined,
  suppliers: [],
  sorting: null,
  documentNumber: null,
  retailerId: null,
  selectedStatistics: null,
  locked: null,
};

const REQUEST_META_DEFAULT = { page: 0, limit: 25, retailerId: null };
const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

@State<GrnsStateModel>({
  name: GRNS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    statistics: Array.from({ length: 5 }),
    exportLoading: false,
  },
})
@Injectable()
export class GrnsState extends EntityListState {
  readonly #store = inject(Store);
  readonly #grnsService = inject(GrnService);
  readonly #accountingIntegrationsService = inject(AccountingIntegrationService);
  readonly #utcOffset = this.#store.selectSignal(SettingsState.utcOffset);

  constructor(protected readonly router: Router) {
    super(router);
  }

  @Selector()
  static grns(state: GrnsStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static postManyResponse(state: GrnsStateModel) {
    return state.GrnPostManyResponse;
  }

  @Selector()
  static currentGrn(state: GrnsStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: GrnsStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: GrnsStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static statisticCards(state: GrnsStateModel) {
    return state.statistics;
  }

  @Selector()
  static exportLoading(state: GrnsStateModel) {
    return state.exportLoading;
  }

  @Selector()
  static currentGrnActivities(state: GrnsStateModel) {
    const grn = GrnsState.currentGrn(state);

    return grn?.updates?.reduce<BaseActivity[]>(
      (acc, cur) => [
        {
          action: cur.type === GrnUpdateType.Transferred ? GrnUpdateType.Pushed : cur.type,
          user: cur.user,
          createdAt: cur.createdAt,
        },
        ...acc,
      ],
      [],
    );
  }

  static grn(id: string, next?: boolean) {
    return createSelector([GrnsState], (state: GrnsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static filters(state: GrnsStateModel) {
    return EntityListState.currentFilters<GrnsFilters>(state);
  }

  @Selector()
  static appliedFiltersCount(state: GrnsStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static requestMetadata(state: GrnsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: GrnsStateModel) {
    return state.responseMetadata;
  }

  @Action(GrnsInitFilters)
  initFilters(ctx: StateContext<GrnsStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(GrnsSetFilters)
  setFilters(ctx: StateContext<GrnsStateModel>, { payload }: GrnsSetFilters) {
    super.setFilter(ctx, payload, FILTERS_DEFAULT);
    ctx.dispatch([new GrnsPatchRequestMeta({ page: 0 }), new GrnsGetManySimple(), new GrnsGetStatistics()]);
  }

  @Action(GrnsPatchFilters)
  patchFilters(ctx: StateContext<GrnsStateModel>, { payload, options }: GrnsPatchFilters) {
    super.patchFilter(ctx, payload, FILTERS_DEFAULT, options);
    ctx.dispatch([new GrnsPatchRequestMeta({ page: 0 }), new GrnsGetManySimple(), new GrnsGetStatistics()]);
  }

  @Action(GrnsResetFilters)
  resetFilters(ctx: StateContext<GrnsStateModel>, { payload }: GrnsResetFilters) {
    super.resetFilter(ctx, {
      ...FILTERS_DEFAULT,
      ...payload,
    });

    ctx.dispatch([new GrnsPatchRequestMeta({ page: 0 }), new GrnsGetManySimple(), new GrnsGetStatistics()]);
  }

  @Action(GrnsPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<GrnsStateModel>, { payload }: GrnsPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(GrnsGet)
  get(ctx: StateContext<GrnsStateModel>, { payload }: GrnsGet) {
    return super.getOne(ctx, {
      ...payload,
      fetchApi: () => this.#grnsService.get(payload.id).pipe(map(Grn.deserialize)),
    });
  }

  @Action(GrnsGetManySimple, { cancelUncompleted: true })
  getManySimple(ctx: StateContext<GrnsStateModel>) {
    return this.#grnsService.getMany(this.getQuery(ctx.getState())).pipe(
      map(({ data, metadata }) => ({
        data: data.map(grn => SimpleGrn.deserialize(grn)),
        metadata,
      })),
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(GrnsResetSequence)
  resetSequenceHandler(ctx: StateContext<GrnsStateModel>) {
    return super.resetSequence(ctx);
  }

  @Action(GrnsGetStatistics)
  getStatistics(ctx: StateContext<GrnsStateModel>) {
    const selectedRetailerId = this.#store.selectSnapshot(CurrentRetailerState.get);
    const currencyPrecision = this.#store.selectSnapshot(SettingsState.currencyPrecision);

    const {
      filters: { branches, suppliers, start, end, locked, status },
    } = ctx.getState();

    const filters: GrnStatisticsRequest = {
      retailer: { id: selectedRetailerId },
      locations: branches?.map(branch => ({ id: branch })),
      suppliers: suppliers?.map(supp => ({ id: supp })),
      startDate: start && new Date(start),
      endDate: end && new Date(end),
      status,
      ...(typeof locked === 'boolean' ? { lockStatus: locked ? 'locked' : 'unlocked' } : {}),
    };

    return this.#grnsService.getStatistics(removeEmpty<GrnStatisticsRequest>(filters)).pipe(
      tap(({ uniqueGrns, totalPurchaseValue, pendingDocuments, negativeInvoices, issuedCreditNotes }) => {
        ctx.patchState({
          statistics: [
            {
              type: GrnStatisticsCardType.UniqueGrns,
              title: $localize`:@@orders.statistics.grns:# GRNs / Invoices`,
              icon: { name: 'clipboard-text', color: 'info' },
              value: uniqueGrns ?? 0,
            },
            {
              type: GrnStatisticsCardType.TotalValue,
              title: $localize`:@@common.purchaseValue:Purchase Value`,
              icon: { name: 'shopping-cart', color: 'success' },
              value: totalPurchaseValue ?? 0,
              precision: currencyPrecision,
            },
            {
              type: GrnStatisticsCardType.PendingDocs,
              title: $localize`:@@orders.statistics.documentPending:Document Pending`,
              icon: { name: 'clipboard-close', color: 'warn' },
              value: pendingDocuments ?? 0,
              relatedFilter: {
                by: 'document.pending',
                op: 'eq',
                match: true,
              },
              clickable: true,
              clearable: true,
            },
            {
              type: GrnStatisticsCardType.NegativeInvoices,
              title: $localize`:@@orders.statistics.negativeInvoices:Negative Invoices`,
              icon: { name: 'clipboard-export', color: 'warn' },
              value: negativeInvoices ?? 0,
              relatedFilter: {
                by: 'totals.total',
                op: 'lt',
                match: 0,
              },
              clickable: true,
              clearable: true,
            },
            {
              type: GrnStatisticsCardType.CreditNotes,
              title: $localize`:@@orders.statistics.issuedCreditNotes:Issued Credit Notes`,
              icon: { name: 'clipboard-tick', color: 'confirm' },
              value: issuedCreditNotes ?? 0,
              relatedFilter: {
                by: 'creditIssued',
                op: 'eq',
                match: true,
              },
              clickable: true,
              clearable: true,
            },
          ],
        });
      }),
    );
  }

  @Action(GrnsCreate)
  create(ctx: StateContext<GrnsStateModel>, { payload: { body } }: GrnsCreate) {
    return this.#grnsService.create(body).pipe(switchMap(({ id }) => ctx.dispatch(new GrnsGet({ id }))));
  }

  @Action(GrnsUpdate)
  update(_: StateContext<GrnsStateModel>, { payload: { id, body } }: GrnsUpdate) {
    return this.#grnsService.update(id, body);
  }

  @Action(GrnsPost)
  post(ctx: StateContext<GrnsStateModel>, { payload: { id } }: GrnsPost) {
    return this.#grnsService.post(id).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 412) {
          ctx.dispatch(
            new GrnsPostFailed({
              statusCode: error.status,
              message:
                (error.error as HttpErrorResponse)?.message ??
                $localize`:@@orders.state.tenantDisconnected:The requested Tenant has been disconnected, please re-establish the connection via the Integration Settings section.`,
            }),
          );
        }

        return throwError(() => error);
      }),
    );
  }

  @Action(GrnsPostMany)
  postMany(ctx: StateContext<GrnsStateModel>, { payload }: GrnsPostMany) {
    return this.#grnsService
      .postMany(payload)
      .pipe(tap(GrnPostManyResponse => ctx.patchState({ GrnPostManyResponse })));
  }

  @Action(GrnsLock)
  lock(_: StateContext<GrnsStateModel>, { id }: GrnsLock) {
    return this.#grnsService.lock(id);
  }

  @Action(GrnsLockMany)
  lockMany(_: StateContext<GrnsStateModel>, { payload }: GrnsLockMany) {
    return this.#grnsService.lockMany(payload);
  }

  @Action(GrnsUnlock)
  unlock(_: StateContext<GrnsStateModel>, { id }: GrnsUnlock) {
    return this.#grnsService.unlock(id);
  }

  @Action(GrnsClearPostMany)
  clearPostMany(ctx: StateContext<GrnsStateModel>) {
    return ctx.patchState({ GrnPostManyResponse: null });
  }

  @Action(GrnsSync)
  sync(_: StateContext<GrnsStateModel>, { payload: { grnId } }: GrnsSync) {
    return this.#accountingIntegrationsService.syncGrn(grnId);
  }

  @Action(GrnsLinkOrder)
  linkOrder(_: StateContext<GrnsStateModel>, { payload: { grnId, orderId } }: GrnsLinkOrder) {
    return this.#grnsService.linkOrder(grnId, orderId);
  }

  @Action(GrnsListExport, { cancelUncompleted: true })
  downloadList(ctx: StateContext<GrnsStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.#grnsService.getMany(query).pipe(
      withLatestFrom(this.#store.select(SettingsState.currency), this.#store.select(SettingsState.ianaTimeZone)),
      tap(([{ data }, currency, ianaTimeZone]) => {
        const mappedData = data.map(grn => SimpleGrn.deserialize(grn));

        ctx.patchState({
          exportLoading: false,
        });

        void downloadGrnList(mappedData, currency, ianaTimeZone);
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  @Action(GrnsDownloadDetailsPdf)
  downloadLPO(_: StateContext<GrnsStateModel>, { orderId }: GrnsDownloadDetailsPdf) {
    return this.#grnsService.downloadPdf(orderId).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl, '_blank');
      }),
    );
  }

  @Action(GrnsListPdfExport, { cancelUncompleted: true })
  downloadPDF(ctx: StateContext<GrnsStateModel>) {
    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.#grnsService.downloadListPdf(query).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl);
      }),
    );
  }

  @Action(GrnsDiscard)
  discard(_: StateContext<GrnsStateModel>, { id }: GrnsDiscard) {
    return this.#grnsService.discard(id);
  }

  private getQuery(
    state: GrnsStateModel,
    extras: {
      readonly paginationLimit?: number;
      readonly offset?: number;
    } = {},
  ): Query<Grn & GrnQueryProps> {
    const { branches, start, end, status, suppliers, documentNumber, selectedStatistics, locked, channelId } =
      state.filters;

    const qb = new QueryBuilder<Grn & GrnQueryProps>({
      filtering: [],
      paging: {
        limit: extras.paginationLimit ? extras.paginationLimit : state.requestMetadata.limit,
        offset: extras.offset ? extras.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'document.documentDate',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (branches?.length) {
      qb.filtering.setFilter({
        by: 'location.id',
        match: branches,
        op: 'in',
      });
    }

    if (suppliers?.length) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: suppliers,
        op: 'in',
      });
    }

    if (channelId) {
      qb.filtering.setFilter({
        by: 'channel.id',
        match: channelId,
        op: 'eq',
      });
    }

    if (start && end && !documentNumber) {
      const utcOffset = this.#utcOffset();

      qb.filtering.withFiltering([
        {
          by: 'document.documentDate',
          op: 'lte',
          match: getShiftedDate(new Date(end), utcOffset).getTime(),
        },
        {
          by: 'document.documentDate',
          op: 'gte',
          match: getShiftedDate(new Date(start), utcOffset).getTime(),
        },
      ]);
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        match: status,
        op: 'eq',
      });
    }

    if (documentNumber) {
      qb.filtering.setFilter({
        by: 'document.number',
        match: documentNumber,
        op: 'like',
      });
    }

    if (typeof locked === 'boolean') {
      qb.filtering.setFilter({
        by: 'metadata.isLocked',
        match: true,
        op: locked ? 'eq' : 'neq',
      });
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (selectedStatistics) {
      const relatedFilter = state.statistics?.find(({ type }) => type === selectedStatistics)?.relatedFilter;

      if (relatedFilter) {
        qb.filtering.setFilter(relatedFilter);
      }
    }

    return qb.build();
  }
}
