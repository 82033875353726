import { Observable } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { GridPagingMode, IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { Store } from '@ngxs/store';

import { BaseRequestMetadata, BaseResponseMetadata, Destroyable, Supplier } from '@supy/common';
import { DialogComponent, GridComponent, IDialogComponent } from '@supy/components';
import { SettingsState } from '@supy/settings';

import { OrderItem, SimpleChannel } from '../../../../core';

@Component({
  selector: 'supy-add-order-items-dialog',
  templateUrl: './add-order-items-dialog.component.html',
  styleUrls: ['./add-order-items-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddOrderItemsDialogComponent extends Destroyable implements IDialogComponent {
  readonly #store = inject(Store);

  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;
  @ViewChild(GridComponent, { static: false }) private readonly grid: GridComponent;

  @Input() set orderItems(value: OrderItem[]) {
    this.#orderItems.set(structuredClone(value ?? []));

    if (value) {
      value.forEach(orderItem => {
        if (orderItem.quantity > 0) {
          this.selectedOrderItems.set(orderItem.productId, orderItem);
        }
      });
    }
  }

  get orderItems(): OrderItem[] {
    return this.#orderItems();
  }

  protected readonly currency = this.#store.selectSignal(SettingsState.currency);
  protected readonly currencyPrecision = this.#store.selectSignal(SettingsState.currencyPrecision);

  @Input() readonly channel: SimpleChannel;
  @Input() readonly supplier: Pick<Supplier, 'name' | 'logoUrl'>;
  @Input() readonly requestMetadata$: Observable<BaseRequestMetadata>;
  @Input() readonly responseMetadata$: Observable<BaseResponseMetadata>;
  @Input() readonly isLoading$: Observable<boolean>;
  @Input() readonly submitButtonText: string = $localize`:@@common.actions.addItems:Add Items`;
  @Input() readonly hideAddNewItemButton: boolean;

  @Output() readonly orderItemsAdded = new EventEmitter<OrderItem[]>();
  @Output() readonly pageChange = new EventEmitter<number>();
  @Output() readonly searchItems = new EventEmitter<string>();
  @Output() readonly createItem = new EventEmitter<void>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();

  protected readonly paginationMode = GridPagingMode.Remote;

  protected readonly selectedOrderItems = new Map<string, OrderItem>();
  readonly #orderItems = signal<OrderItem[]>([]);

  protected onSearch(term: string): void {
    this.grid.filterColumnByString(term, 'name');
  }

  protected onAdd(): void {
    this.grid.endEdit();
    this.orderItemsAdded.emit(Array.from(this.selectedOrderItems.values()));
  }

  protected onQuantityChanges(orderItem: OrderItem, quantity: number): void {
    if (quantity > 0) {
      this.selectedOrderItems.set(orderItem.productId, {
        ...orderItem,
        quantity,
      });
    } else {
      this.selectedOrderItems.delete(orderItem.productId);
    }
  }

  protected getQuantity(orderItem: OrderItem): number {
    return this.selectedOrderItems.get(orderItem.productId)?.quantity ?? 0;
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
