import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { IQueryResponse, QueryBuilder } from '@supy/common';

import { Order } from '../../core';
import { OrdersService } from '../../services';

@Injectable()
export class OrdersResolver {
  constructor(private readonly ordersService: OrdersService) {}

  resolve(): Promise<IQueryResponse<Order>> | Observable<IQueryResponse<Order>> {
    const qb = new QueryBuilder<Order>({
      filtering: [],
      paging: { offset: 0, limit: 50 },
    });

    return this.ordersService.getOrdersBff(qb.build());
  }
}
