import {
  IdType,
  LocalizedSkeletonObjectType,
  Retailer,
  SimpleUser,
  SkeletonObjectType,
  Supplier,
  User,
} from '@supy/common';

import { AggregatedLinkedOrder, PercentageValue, SimpleChannel } from '../order';
import { GrnUpdateType } from './grn.entity';
import { GrnCreditNoteResponse } from './grn-credit-note.model';
import { GrnDocumentRequest, GrnDocumentResponse, GrnSimpleDocumentResponse } from './grn-document.model';
import {
  GrnCreditNoteItemResponse,
  GrnItemCreateRequest,
  GrnItemResponse,
  GrnItemUpdateRequest,
} from './grn-item.model';

export enum GrnStatus {
  Draft = 'draft',
  Saved = 'saved',
  Posted = 'posted',
  Incoming = 'pending',
  Disputed = 'disputed',
  Discarded = 'discarded',
  New = 'new',
}

export enum GrnType {
  Invoice = 'invoice',
  DeliveryNote = 'delivery-note',
  ConsolidatedInvoice = 'consolidated-invoice',
}

export interface GrnBaseResponse {
  readonly id: string;
  readonly supplier?: Supplier;
  readonly location?: SkeletonObjectType;
  readonly outlet?: LocalizedSkeletonObjectType;
  readonly channel?: SimpleChannel;
  readonly isSynced?: boolean;
  readonly creditIssued?: boolean;
  readonly type: GrnType;
  readonly metadata?: GrnMetadata;
  readonly totals?: GrnTotals;
  readonly status: GrnStatus;
  readonly linkedOrders?: AggregatedLinkedOrder[];
}

export interface SimpleGrnResponse extends GrnBaseResponse {
  readonly total?: number;
  readonly document?: GrnSimpleDocumentResponse;
  readonly retailer?: SkeletonObjectType;
}
export interface GrnResponse extends GrnBaseResponse {
  readonly retailer?: Retailer;
  readonly preferred?: boolean;
  readonly user: SimpleUser;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly updates: GrnUpdates[];
  readonly document: GrnDocumentResponse;
  readonly items: Array<GrnItemResponse | GrnCreditNoteItemResponse>;
  readonly comment?: string;
  readonly creditNotes?: GrnCreditNoteResponse[];
  readonly linkedGrns?: IdType[];
}

export interface GrnQueryProps {
  readonly id: string;
  readonly status: string;
  readonly 'supplier.id': string;
  readonly 'retailer.id': string;
  readonly 'location.id': string;
  readonly 'channel.id': string;
  readonly 'document.number': string;
  readonly 'document.documentDate': number;
  readonly 'metadata.isLocked': boolean;
  readonly 'document.pending': boolean;
  readonly 'totals.total': number;
}

export class GrnUpdates {
  readonly type: GrnUpdateType;
  readonly comment?: string;
  readonly user: User;
  readonly createdAt: Date;
}

export interface GrnDocumentDiscrepancies {
  readonly number: boolean;
  readonly documentDate: boolean;
  readonly paymentDueDate: boolean;
}

export interface GrnMetadata {
  readonly isLocked?: boolean;
  readonly isSynced?: boolean;
  readonly autoGrn?: {
    readonly documentDiscrepancies?: GrnDocumentDiscrepancies;
    readonly skippedItemCodes?: string[];
  };
}

export interface GrnTotals {
  readonly subTotal: number;
  readonly discount: PercentageValue;
  readonly taxAmount: number;
  readonly fees: number;
  readonly total: number;
}

export interface GrnBaseRequest {
  readonly status: GrnStatus;
  readonly document?: GrnDocumentRequest;
  readonly comment?: string;
  readonly pushToStock?: boolean;
  readonly closeOrders?: boolean;
}

export interface GrnCreateRequest extends GrnBaseRequest {
  readonly items: GrnItemCreateRequest[];
  readonly supplier: IdType;
  readonly location: IdType;
  readonly channel: IdType;
  readonly linkedOrders: IdType[];
  readonly totals: GrnTotals;
}

export interface GrnUpdateRequest extends GrnBaseRequest {
  readonly items: GrnItemUpdateRequest[];
  readonly totals?: GrnTotals;
}

export interface GrnPostManyRequest {
  readonly grns: IdType[];
}

export type GrnLockManyRequest = GrnPostManyRequest;

export interface GrnSyncedResponse {
  readonly synced: string[];
  readonly unsynced: string[];
}

export type GrnPostManyResponse = GrnSyncedResponse | void;

export interface GrnDownloadPdfResponse {
  readonly signedUrl: string;
}

export interface GrnUploadFileResponse {
  readonly path: string;
}

export interface GrnStatistics {
  readonly uniqueGrns?: number;
  readonly totalPurchaseValue?: number;
  readonly pendingDocuments?: number;
  readonly negativeInvoices?: number;
  readonly issuedCreditNotes?: number;
  readonly draftedGrns?: number;
  readonly savedGrns?: number;
  readonly postedGrns?: number;
}

export interface GrnStatisticsRequest {
  readonly retailer: IdType;
  readonly locations?: IdType[];
  readonly suppliers?: IdType[];
  readonly startDate?: Date;
  readonly endDate?: Date;
  readonly status?: GrnStatus;
  readonly lockStatus?: 'locked' | 'unlocked';
}

export interface GrnStatisticsCard {
  readonly 'document.pending': boolean;
  readonly 'totals.total': number;
  readonly creditIssued: boolean;
}
